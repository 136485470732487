import React, { useState } from 'react';
import { Link } from 'react-router-dom';
/* import arcadaLogo from '../../images/arcada_logo_white.webp'; */

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ResponsiveDrawer from './ResponsiveDrawer';


const NavBar = () => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMenuOpen(!menuOpen);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <header className={classes.root}>
      <AppBar component='div' position='fixed' elevation={4} style={{ backgroundColor: '#1B6AFE' }}>
        <Toolbar>
          <IconButton
            edge='start'
            className={classes.menuButton}
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
          <Link to='/' style={{ textDecoration: 'none' }}>
            <Typography className={classes.title} variant='caption' noWrap>
              {/* <img src={arcadaLogo} alt='arcada-logo' className={classes.logo} /> */}
              Studera på svenska i Finland
            </Typography>
          </Link>
          {/* <SearchField term={headSearchInputValue} label='head' placeholder='Sök...'/> */}
        </Toolbar>
      </AppBar>
      <ResponsiveDrawer menuOpen={menuOpen} handleDrawerToggle={handleDrawerToggle} />
    </header>
  );
};

export default NavBar;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontFamily: 'montserrat',
    fontWeight: '500',  
    color: '#fff',
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  logo: {
    maxWidth: '6rem',
    marginTop: '7px',
  },
}));
