import Axios from 'axios';

const callerID = process.env.REACT_APP_CALLER_ID;

const api = Axios.create({
  baseURL: 'https://opintopolku.fi/konfo-backend/external/',
  timeout: 1000,
  headers: {
    'Caller-Id': callerID,
  },
});
export const getFilters = async () => {
  const filters = await api.get('/search/filters');
  return filters;
};

export const getPrograms = async ({ ...args }) => {
  //console.log(args);
  let keyword = args.keyword.length < 1 ? '' : `keyword=${args.keyword}&`;
  let amnen = args.koulutusala.length < 1 ? '' : `koulutusala=${args.koulutusala.join(', ')}&`;
  let studieort = args.sijainti.length < 1 ? '' : `sijainti=${args.sijainti.join(', ')}&`;
  let utbildningstyp =
    args.koulutustyyppi < 1
      ? 'koulutustyyppi=kandi%2Ckandi-ja-maisteri%2Cmaisteri%2Camk-alempi%2Camk-ylempi&'
      : `koulutustyyppi=${args.koulutustyyppi.join(', ')}&`;
  let undervisningsmetod = args.opetustapa.length < 1 ? '' : `opetustapa=${args.opetustapa.join(', ')}&`;
  let ansokningsomgang = args.yhteishaku.length < 1 ? '' : `yhteishaku=${args.yhteishaku}&`;

  let hakukaynnissa = args.ansokningssatt.find((item) => item.includes('Ansökan pågår')) ? `hakukaynnissa=true&` : '';
  let hakutapa = args.hakutapa.filter((item) => !item.includes('hakukaynnissa'));
  let ansokningssatt = hakutapa.length < 1 ? '' : `hakutapa=${hakutapa}&`;
  let opetuskieli =
    args.opetuskieli.length < 1 ? 'opetuskieli=oppilaitoksenopetuskieli_2' : `opetuskieli=oppilaitoksenopetuskieli_2,${args.opetuskieli.join(', ')}`;

  const programs = await api.get(
    `/search/toteutukset-koulutuksittain?${keyword}page=${args.page}&size=15&lng=sv&sort=score&order=desc&${hakukaynnissa}${amnen}${studieort}${utbildningstyp}${undervisningsmetod}${ansokningsomgang}${ansokningssatt}${opetuskieli}`
  );

  return programs;
};

// TOTEUTUS 
export const getSingleProgramme = async (oid) => {
  if (oid) {
    const programme = await api.get(
      `/toteutus/${oid}?toteutukset=true&hakukohteet=true&haut=true`
    ).catch((error) => {
      // really hacky solution for fixing a backend problem on the frontend 
      // sometimes opintopolku returns 500 error with the data we need in `error.response.data.value` making react query fail 
      // this forces us to handle the error here 
      if(error.response?.status === 500 && error.response?.data?.value?.oid === oid ){
      console.warn("forcefully overriding getSingleProgramme response due to the following error", error.response?.data?.errors)
        return {
          data: error.response.data.value,
          status: 200, //forcefully changing it to 200 
          statusText: 'NOT OK BUT WE PRETEND IT IS',
          headers: error.response.headers,
          config: error.response.config,
          request: error.response.request,
        }
      }
    });
    return programme;
  } else {
    console.warn("oid in query was not truthy!")
  }
};

// KOULUTUS 
export const getSingleEducation = async (oid) => {
  if (oid){
    const programme = await api.get(
      `/koulutus/${oid}?toteutukset=true&hakukohteet=true&haut=true`
    ).catch((error) => {
      // really hacky solution for fixing a backend problem on the frontend 
      // sometimes opintopolku returns 500 error with the data we need in `error.response.data.value` making react query fail 
      // this forces us to handle the error here 
      if(error.response?.status === 500 && error.response?.data?.value?.oid === oid ){
      console.warn("forcefully overriding getSingleEducation response due to error: response object may contain more info: ", error.response)
        return {
          data: error.response.data.value,
          status: 200, //forcefully changing it to 200 
          statusText: 'NOT OK BUT WE PRETEND IT IS',
          headers: error.response.headers,
          config: error.response.config,
          request: error.response.request,
        }
      }
    });
    return programme;
  } else {
    console.warn("oid in query was not truthy!")
  }
};