import React from 'react';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// import { IconButton, Tooltip } from '@material-ui/core';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import TwitterIcon from '@material-ui/icons/Twitter';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';

import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
    /* minHeight: '20em', */
    /*  [theme.breakpoints.down('xs')]: {
      minHeight: '26em',
    }, */
    backgroundColor: '#5f5f5f',
    border: '1px solid #555',
    bottom: 0,
  },
  container: {
    maxWidth: 1800,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-end',
    fontFamily: 'trispace',
    fontStyle: 'none',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '25em',
    },
    color: '#f9f9f9',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
  },
  icon: {
    color: '#b0bec5',
    '&:hover': {
      color: '#ff9100',
    },
  },
  text: {
    color: '#f9f9f9',
    fontFamily: 'trispace',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    padding: '0.2rem',
  },
  adress: {
    alignItems: 'flex-end',
    fontFamily: 'trispace',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    padding: '0.2rem',
  },
  link:{
    display: 'block',
    color: '#fff',
    fontSize: '0.75rem',
    fontFamily: 'trispace',
    padding: '0.2rem',
  }
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.root}>
      <div className={classes.container}>

        <div>   
          <Link to="/sidkarta" className={classes.link}>Sidkarta</Link>
          <Link to="/sekretesspolicy" className={classes.link}>Sekretess policy</Link>
          <Link to="/tillganglighet" className={classes.link}>Tillgänglighets utlåtande</Link>
        </div>
              

        <div className={classes.contacts}>
          <address className={classes.adress}>
            info@studerapasvenska.fi 
          </address>
          <Typography variant='caption' component='p' className={classes.text}>
          &copy; 2021 Studera på svenska i Finland
        </Typography>
        </div>
       
        {/* <div className={classes.find_us}>
          <Typography variant='h6' component='p' color='inherit' style={{ paddingLeft: 10 }}>
            Följ oss
          </Typography>
          <div className='icons'>
            <Tooltip title='Facebook'>
              <IconButton aria-label='facebook' onClick={() => console.log('facebook link')}>
                <FacebookIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
            <Tooltip title='Twitter'>
              <IconButton aria-label='twitter' onClick={() => console.log('twitter link')}>
                <TwitterIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
            <Tooltip title='Instagram'>
              <IconButton aria-label='instagram' onClick={() => console.log('instagram link')}>
                <InstagramIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
            <Tooltip title='Linkedin'>
              <IconButton aria-label='linkedin' onClick={() => console.log('linked link')}>
                <LinkedInIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          </div>
        </div> */}
      </div>
  
    </footer>
  );
};

export default Footer;
