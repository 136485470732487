import React from 'react';
import { Link } from 'react-router-dom';

// Material-UI
import { makeStyles, useTheme } from '@material-ui/styles';
import { Drawer, Divider, List, ListItem, ListItemText } from '@material-ui/core';
//import { MenuBook, Collections, SpeakerNotes } from '@material-ui/icons';

/* const drawerWidth = 270; */

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      width: 270,
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: 270,
    [theme.breakpoints.down('xs')]: {
      width: 160,
    },
  },
  menuIcons: {
    color: '#5B96FE',
    marginRight: '10px',
  },
  listItem: {
    color: '#5B96FE',
  },
}));

const ResponsiveDrawer = ({ menuOpen, handleDrawerToggle }) => {
  const classes = useStyles();
  const theme = useTheme();

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <Link to='/' style={{ textDecoration: 'none' }}>
          <ListItem button onClick={handleDrawerToggle} className={classes.listItem}>
            {/* <MenuBook className={classes.menuIcons} /> */}
            <ListItemText primary={'Hem'} />
          </ListItem>
        </Link>
        <Link to='/favorites' style={{ textDecoration: 'none' }} className={classes.listItem}>
          <ListItem button onClick={handleDrawerToggle}>
            {/* <Collections className={classes.menuIcons} /> */}
            <ListItemText primary={'Sparade utbildningar'} />
          </ListItem>
        </Link>
        <Link to='/about' style={{ textDecoration: 'none' }} className={classes.listItem}>
          <ListItem button onClick={handleDrawerToggle}>
            {/* <SpeakerNotes className={classes.menuIcons} /> */}
            <ListItemText primary={'Om projektet'} />
          </ListItem>
        </Link>
      </List>
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label='categories'>
      <Drawer
        variant='temporary'
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={menuOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}>
        {drawer}
      </Drawer>
    </nav>
  );
};

export default ResponsiveDrawer;
