import React from 'react';

// Material-UI
import { makeStyles } from '@material-ui/styles';
import { Box, Typography, CircularProgress } from '@material-ui/core';

//REDUX
import { useSelector } from 'react-redux';

const About = () => {
  const classes = useStyles();
  const CMSdata = useSelector((state) => state.filters.CMSdataForAboutPage);

  console.log(CMSdata);
  let links = [];

  if (CMSdata) {
    let texts = CMSdata.links_text;
    let urls = CMSdata.links_url;
    console.log({ texts, urls });

    for (let i = 0; i < texts.length; i++) {
      for (let i = 0; i < urls.length; i++) {
        if (urls[i] === null) {
          return `https://studerapasvenska.fi/about`;
        }
      }
      let link = { text: texts[i], url: urls[i] };
      links.push(link);
    }
    links.shift();
  }

  if (!CMSdata) {
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <Box component='main' style={{ minHeight: '84.9vh' }}>
      <div className={classes.title}>
        <Typography id='main' variant='h1' component='h1' style={{ fontSize: 'calc(2em + 1vw)', fontWeight: 'bold', fontFamily: 'youngserif' }}>
          {CMSdata.title}
        </Typography>
      </div>
      <div className={classes.text}>
        <Typography component='p' variant='subtitle1'>
          {CMSdata.description}
          <a href={CMSdata.links_url[0]} target='_blank' rel='noopener noreferrer' style={{ color: 'inherit' }}>
            {' '}
            {CMSdata.links_text[0]}
          </a>
          .
        </Typography>
        <br />
        <Typography component='p' variant='subtitle1'>
          {CMSdata.subDescription}
        </Typography>
      </div>
      <ul className={classes.links}>
        {links?.map((link, index) => (
          <li key={index} style={{ marginTop: 20 }}>
            <a href={link.url} target='_blank' rel='noopener noreferrer' style={{ color: 'inherit' }}>
              {link.text}
            </a>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default About;

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    marginTop: '5rem',
    padding: '20px 60px',
    [theme.breakpoints.down('sm')]: {
      flex: '1',
      padding: '0',
    },
  },
  text: {
    maxWidth: '60rem',
    padding: '2rem',
    margin: '1rem auto',
  },
  links: {
    maxWidth: '60rem',
    padding: '2rem',
    margin: '1rem auto',
    listStyleType: 'none',
  },
  spinner: {
    minWidth: '100%',
    maxWidth: 1800,
    minHeight: '100vh',
    padding: theme.spacing(5, 0),
    margin: '12rem auto',
    textAlign: 'center',
  },
}));
