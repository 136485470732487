/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useLocation } from 'react-router-dom';

// Components
import Categories from './Categories';
import SearchPanel from './SearchPanel';
import FilterChips from './FilterChips';
import LoadingSkeleton from '../customs/LoadingSkeleton';
import SearchResult from '../functions/SearchResult';

// Material-UI
import { Box, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import mainPhoto from '../../images/Misc/hero-books.webp';

// React Query
import { useQuery } from 'react-query';
import { getPrograms } from '../../lib/api';

// REDUX
import { useSelector } from 'react-redux';

const Home = () => {
  const classes = useStyles();
  const location = useLocation();
  const { queries } = useSelector((state) => state.filters);
  const { isLoading, isError, error, data, refetch } = useQuery(
    ['programs', queries.page],
    () => getPrograms(queries),
    { keepPreviousData: true } //pagination glitch fix
  );
  // console.log({ data });
  return (
    <Box component='main' className={classes.root}>
      <Container maxWidth='lg' className={classes.container}>
        <div className={classes.headSection}>
          <Typography id='main' variant='h1' className={classes.title}>
            Studera <br />
            på svenska <br />i Finland
          </Typography>
          <Typography variant='h2' className={classes.subtitle}>
            <br />
            Hitta intressanta <br />
            utbildningar och bestäm <br />
            dig för vart du vill söka!
          </Typography>
        </div>
        <div className={classes.headSection} style={{ textAlign: 'center' }}>
          <img src={mainPhoto} alt='' className={classes.img} />
        </div>
      </Container>
      <SearchPanel refetch={refetch} />
      <FilterChips />
      <LoadingSkeleton />
      {location.pathname === '/search' && <SearchResult isLoading={isLoading} isError={isError} error={error} data={data} />}

      <Categories refetch={refetch} isLoading={isLoading}/>
    </Box>
  );
};

export default Home;

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
    maxWidth: 1800,
    minHeight: '100vh',
    padding: theme.spacing(5, 0),
    marginTop: '3rem',
    /* margin: 'auto', */
  },
  container: {
    maxWidth: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      maxWidth: '100%',
    },
  },
  title: {
    fontSize: 'calc(3em + 1.5vw)',
    fontWeight: 'bold',
    fontFamily: 'youngserif',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.7rem',
      '& > br': {
        display: 'none',
      },
    },
  },
  subtitle: {
    fontSize: 'calc(1em + 0.1vw)',
    fontWeight: '500',
    fontFamily: 'trispace',
    paddingLeft: '0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.85rem',
      '& > br': {
        display: 'none',
      },
    },
  },
  headSection: {
    flex: 'none',
    //padding: '20px 60px',
    [theme.breakpoints.down('sm')]: {
      flex: '1',
      padding: '0',
      width: '100%',
    },
  },
  img: {
    minWidth: '34em',
    maxWidth: '34em',

    [theme.breakpoints.down('xs')]: {
      minWidth: '20em',
      maxWidth: '20em',
    },
  },
  btn: {
    margin: '3rem  0 auto',
    textAlign: 'center',
  },
}));
