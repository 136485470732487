import React from 'react';

// Material-UI
import { IconButton, Icon } from '@material-ui/core';

//REDUX
import { useDispatch, useSelector } from 'react-redux';
import { storeFavorite, removeFavorite } from '../../actions/filterActions';

const FavoriteButton = ({ item }) => {
  const { favoriteStudyOptions } = useSelector((state) => state.filters);
  const favorite = item.koulutusOid ? favoriteStudyOptions.includes(item.koulutusOid) : favoriteStudyOptions.includes(item.oid);
  const ITEM = Object.assign(item, { favorite: favorite });

  const dispatch = useDispatch();

  const toggleFavorites = (target) => {
    if (!target.favorite) {
      if (target.koulutusOid) {
        dispatch(storeFavorite(target.koulutusOid));
      } else {
        dispatch(storeFavorite(target.oid));
      }
    } else {
      if (target.koulutusOid) {
        dispatch(removeFavorite(target.koulutusOid));
      } else {
        dispatch(removeFavorite(target.oid));
      }
    }
  };
  return (
    <IconButton aria-label='favorite button' size='small' onClick={() => toggleFavorites(ITEM)}>
      <Icon color='primary'>{ITEM.favorite ? 'favorite' : 'favorite_border'}</Icon>
    </IconButton>
  );
};

export default FavoriteButton;
