/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

// Components
import SearchField from '../customs/SearchField';
import SelectField from '../customs/SelectField';
import NotLoaded from './NotLoaded';

import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

// React Query
import { useQuery } from 'react-query';
import { getFilters } from '../../lib/api';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getFilterOptions, getOption, setQuery } from '../../actions/filterActions';

// Material-UI
import {
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Divider,
  IconButton,
  Collapse,
  Grow,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';
import { makeStyles } from '@material-ui/core/styles';

const SearchPanel = ({ refetch }) => {
  const location = useLocation();
  const { isLoading, isError, error, data } = useQuery('filters', getFilters);
  const {
    queries,
    amnen,
    studieort,
    ansokningsomgang,
    utbildningstyp,
    undervisningsmetod,
    ansokningssatt,
    ämnenOption,
    regionOption,
    ansökningsomgångOption,
    utbildningstypOption,
    ansokningssattOption,
    finskaOption,
    engelskaOption,
    undervisningsmetodOption,
  } = useSelector((state) => state.filters);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      dispatch(getFilterOptions(data));
    }
  }, [data, dispatch]);

  const classes = useStyles();

  const [checked, setChecked] = useState(false);

  const handleTuneClick = () => {
    setChecked((prev) => !prev);
  };

  const selectedOption = (label, value) => {
    let finska = label === 'Finska' && value === true && 'finska';
    let engelska = label === 'Engelska' && value === true && 'engelska';

    dispatch(getOption(label, value));
    if (label === 'Finska') {
      dispatch(
        setQuery({
          ...queries,
          undervisningssprak: finska
            ? [...queries.undervisningssprak, finska]
            : queries.undervisningssprak.filter((item) => !item.includes('finska')),
        })
      );
    }
    if (label === 'Engelska') {
      dispatch(
        setQuery({
          ...queries,
          undervisningssprak: engelska
            ? [...queries.undervisningssprak, engelska]
            : queries.undervisningssprak.filter((item) => !item.includes('engelska')),
        })
      );
    }
  };

  const handleSearch = (value) => {
    if (window.innerWidth < 600) {
      window.scrollTo({
        top: 480,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: 350,
        behavior: 'smooth',
      });
    }
    refetch();
  };

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <CircularProgress /> <p>Search Panel is Loading ...</p>
      </div>
    );
  }

  if (isError) {
    return (
      <div className={classes.root} spacing={3}>
        <NotLoaded errorMsg={error.message} />
      </div>
    );
  }

  return (
    <Grow in={!isLoading} timeout={1000}>
      <div className={classes.root} spacing={3}>
        <Grid container>
          <div className={classes.title}>SÖK UTBILDNING</div>
          <Grid item xs={8} sm={11} className={classes.search}>
            <SearchField placeholder='FRI SÖKNING' />
            <IconButton aria-label='filter-icon' className={classes.tuneBtn} onClick={handleTuneClick}>
              <TuneIcon />
            </IconButton>
          </Grid>

          <Grid item xs={8} sm={11} className={classes.checkboxes}>
            <FormGroup row>
              <span className={classes.checkboxText}>Visa utbildningar som delvis är på</span>
              <FormControlLabel
                label={<Typography style={{ fontFamily: 'montserrat' }}>finska</Typography>}
                control={
                  <Checkbox
                    color='primary'
                    size='small'
                    style={{ marginLeft: '6px', padding: '6px' }}
                    onChange={(e) => selectedOption('Finska', e.target.checked)}
                    checked={finskaOption}></Checkbox>
                }></FormControlLabel>
              <FormControlLabel
                label={<Typography style={{ fontFamily: 'montserrat' }}>engelska</Typography>}
                control={
                  <Checkbox
                    color='primary'
                    size='small'
                    style={{ marginLeft: '6px', padding: '6px' }}
                    onChange={(e) => selectedOption('Engelska', e.target.checked)}
                    checked={engelskaOption}></Checkbox>
                }></FormControlLabel>

              <Tooltip
                fontSize='small'
                placement='right'
                title='Utbildningarnas språk anges av högskolorna. För mer information om ett programs undervisningsspråk, kontakta högskolan ifråga.'>
                <IconButton aria-label='språk info'>
                  <InfoOutlinedIcon color='primary' />
                </IconButton>
              </Tooltip>
            </FormGroup>
          </Grid>

          <Collapse in={checked} className={classes.collapse}>
            <Grid item xs={8} sm={11} className={classes.selectContainer}>
              <Divider />
            </Grid>
            {amnen && studieort && undervisningsmetod && utbildningstyp && ansokningssatt && (
              <Grid container item xs={8} sm={11} justify='flex-start' direction='row' className={classes.selectContainer}>
                <Grid item xs={8} sm={3} className={classes.select} onClick={() => console.log('amnen')}>
                  <SelectField terms={amnen} option={ämnenOption} label='Ämnen' refetch={refetch} />
                </Grid>
                <Grid item xs={8} sm={3} className={classes.select} onClick={() => console.log('studieort')}>
                  <SelectField terms={studieort} option={regionOption} label='Studieort' refetch={refetch} />
                </Grid>
                <Grid item xs={8} sm={3} className={classes.select} onClick={() => console.log('ansokningsomgang')}>
                  <SelectField terms={ansokningsomgang} option={ansökningsomgångOption} label='Ansokningsomgang' refetch={refetch} />
                </Grid>
                <Grid item xs={8} sm={3} className={classes.select} onClick={() => console.log('undervisningsmetod')}>
                  <SelectField terms={undervisningsmetod} option={undervisningsmetodOption} label='Undervisningsmetod' refetch={refetch} />
                </Grid>
                <Grid item xs={8} sm={3} className={classes.select} onClick={() => console.log('utbildningstyp')}>
                  <SelectField terms={utbildningstyp} option={utbildningstypOption} label='Utbildningstyp' refetch={refetch} />
                </Grid>
                <Grid item xs={8} sm={3} className={classes.select} onClick={() => console.log('Ansökningssätt')}>
                  <SelectField terms={ansokningssatt} option={ansokningssattOption} label='Ansökningssätt' refetch={refetch} />
                </Grid>
              </Grid>
            )}
          </Collapse>

          <Grid item xs={8} sm={11} className={classes.buttons}>
            {location.pathname !== '/search' ? (
              <Link to='/search' style={{ textDecoration: 'none' }}>
                <Button disabled={isLoading || isError} onClick={handleSearch} variant='contained' color='primary' className={classes.button}>
                  SÖK
                </Button>
              </Link>
            ) : (
              <Button disabled={isLoading || isError} onClick={handleSearch} variant='contained' color='primary' className={classes.button}>
                SÖK
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    </Grow>
  );
};

export default SearchPanel;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 1000,
    //backgroundColor: '#EEF2C2',
    border: '2px solid',
    borderColor: '#5b96ff',
    borderRadius: '10px',
    boxShadow: '3px 3px 20px -8px rgba(0,0,0,0.75)',
    padding: theme.spacing(4),
    margin: '2rem auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 900,
      padding: theme.spacing(2, 0),
      margin: '1rem',
      marginTop: '2.5rem',
    },
  },
  select: {
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },
  },
  selectContainer: {
    margin: 'auto',
    padding: 'auto 2.5rem',
  },
  search: {
    margin: 'auto',
    height: '2.5rem',
    display: 'flex',
    flexFlow: 'row no-wrap',
    [theme.breakpoints.down('md')]: {
      marginTop: '1rem',
    },
  },
  searchBar: {
    height: '2.5rem',
    width: '100%',
    flexGrow: '5',
  },
  tuneBtn: {
    flexGrow: '0',
    height: '2.5rem',
    width: '2.5rem',
    marginLeft: '1rem',
  },
  title: {
    padding: 'auto 2.5rem',
    marginLeft: '2.5rem',
    fontSize: '1.2rem',
    marginBottom: '15px',
    letterSpacing: '0.1rem',
    fontFamily: 'montserrat-bold',
    color: '#505050',
  },
  collapse: {
    width: '100%',
  },
  checkboxes: {
    margin: ' 1rem auto',
    padding: 'auto 2.5rem',
    fontSize: '0.9rem',
  },
  checkboxText: {
    fontSize: '0.9rem',
    lineHeight: '1.5rem',
    padding: '12px',
    paddingLeft: '0',
    color: '#000',
    fontFamily: 'montserrat',
  },
  button: {
    width: '120px',
    marginLeft: '1rem',
    fontFamily: 'trispace',
    fontWeight: '600',
    letterSpacing: '0.15em',
    [theme.breakpoints.down('sm')]: {
      width: '90px',
    },
  },
  buttons: {
    margin: 'auto',
    padding: 'auto 2.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    /*  paddingTop: '50%', */
  },
}));
