
import React from 'react';

// Material-UI
import { makeStyles } from '@material-ui/styles';
import { Box, Typography, CircularProgress } from '@material-ui/core';

//REDUX
import { useSelector } from 'react-redux';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const PrivacyPolicy = () => {
  const classes = useStyles();
  const CMSdata = useSelector((state) => state.filters.CMSdataForPrivacyPolicyPage);

  console.log(CMSdata);
  
 
  const options = {
     // contetful linebreaks to work
    renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  }

  if (CMSdata) {
    let content = CMSdata.content;
    console.log(content);
  }

  if (!CMSdata) {
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <Box component='main' style={{ minHeight: '84.9vh' }}>
      <div className={classes.title}>
        <Typography
          id='main'
          variant='h1'
          component='h1'
          style={{ fontSize: 'calc(2em + 1vw)', fontWeight: 'bold', fontFamily: 'youngserif' }}>
        Sekretesspolicy
        </Typography>
      </div>
      <div className={classes.text}>
      <Typography  component='p' variant='subtitle1'>
        {documentToReactComponents(CMSdata.content, options)}
      </Typography>
      </div>
    </Box>
  );
};

export default PrivacyPolicy;

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    marginTop: '5rem',
    padding: '20px 60px',
    [theme.breakpoints.down('sm')]: {
      flex: '1',
      padding: '0',
    },
  },
  text: {
    maxWidth: '60rem',
    padding: '2rem',
    margin: '1rem auto',
  },
  links: {
    maxWidth: '90%',
    padding: '1rem',
    margin: '1rem auto',
    listStyleType: 'none',
  },
  spinner: {
    minWidth: '100%',
    maxWidth: 1800,
    minHeight: '100vh',
    padding: theme.spacing(5, 0),
    margin: '12rem auto',
    textAlign: 'center',
  },
}));
