/* eslint-disable array-callback-return */
import {
  INIT_FILTERS,
  INIT_QUERY_DATA,
  SET_QUERY,
  INIT_DATA,
  FAIL_DATA,
  STORE_FAVORITE,
  REMOVE_FAVORITE,
  GET_OPTION,
  GET_VALUE,
  PAGINATE,
  LOAD_RESULT,
  REFRESH_QUERY_DATA,
} from '../actions/types';

import { storeData, getData } from '../helpers/localStorage';

const initialState = {
  CMSdataForAboutPage: null,
  CMSdataForAccessibilityPage: null,
  CMSdataForPrivacyPolicyPage: null,
  filterOptions: null,
  amnen: [],
  studieort: [],
  ansokningsomgang: [],
  utbildningstyp: [],
  undervisningsmetod: [],
  ansokningssatt: [],
  undervisningssprak: [],
  studyOptionsDataNew: null,
  hogskolor: [],
  queries: {
    keyword: '',
    page: 1,
    size: 15,
    amnen: [],
    koulutusala: [],
    studieort: [],
    sijainti: [],
    utbildningstyp: [],
    koulutustyyppi: [],
    undervisningsmetod: [],
    opetustapa: [],
    ansokningsomgang: [],
    yhteishaku: [],
    hakukaynnissa: false,
    ansokningssatt: [],
    hakutapa: [],
    undervisningssprak: [],
    opetuskieli: [],
    hogskola: '',
  },
  // OLD
  regionOptions: [],
  UtbildningsformOptions: [
    { value: 'Närundervising', label: 'Närundervising' },
    { value: 'Distansundervisning', label: 'Distansundervisning' },
    { value: 'Nätundervisning', label: 'Nätundervisning' },
    { value: 'Flerform', label: 'Flerform' },
    { value: 'Självständiga studier', label: 'Självständiga studier' },
  ],
  UtbildningstypOptions: [
    { value: 'Universitet lägre', label: 'Universitet lägre' },
    { value: 'Universitet högre', label: 'Universitet högre' },
    { value: 'Yrkeshögskola lägre', label: 'Yrkeshögskola lägre' },
    { value: 'Yrkeshögskola högre', label: 'Yrkeshögskola högre' },
  ],
  AnsökningsomgångOptions: [
    { value: 'Ansökan pågår', label: 'Ansökan pågår' },
    {
      value: 'Högskolornas första gemensamma ansökan',
      label: 'Högskolornas första gemensamma ansökan',
    },
    {
      value: 'Högskolornas andra gemensamma ansökan',
      label: 'Högskolornas andra gemensamma ansökan',
    },
  ],
  ÄmnenOptions: [
    { value: 'Allmänbildande', label: 'Allmänbild' },
    { value: 'Handel, ekonomi och finansiering', label: 'handel, ekonom, finans' },
    { value: 'Historia, traditioner och kultur', label: 'histori, tradition, kultur' },
    {
      value: 'Konst, Musik, hantverk och formgivning',
      label: 'konst, musik, hantverk, formgiv',
    },
    { value: 'Lag och samhälle', label: 'lag, samhäl' },
    { value: 'Lant och skogsbruk, miljö', label: 'lant, bruk, miljö' },
    { value: 'Naturvetenskaper', label: 'vetenskaper' },
    {
      value: 'Språk, information och kommunikation',
      label: 'språk, inform, kommunik',
    },
    { value: 'Teknik', label: 'teknik' },
    { value: 'Trafik och transport', label: 'trafik, transport' },
    { value: 'Undervisning, fostran och psykologi', label: 'undervis, fostra, psykologi' },
    { value: 'Vård och välmående tjänster', label: 'vård, välmå,  tjänster' },
  ],
  // we need them for the option of select fields
  regionOption: [],
  utbildningsformOption: [],
  ansökningsomgångOption: [],
  utbildningstypOption: [],
  ämnenOption: [],
  undervisningsmetodOption: [],
  ansokningssattOption: [],
  finskaOption: false,
  engelskaOption: false,
  ///////////////////////////////////////////
  searchResultIsLoading: false,
  searchInputValue: '',
  headSearchInputValue: '',
  filteredData: [],
  dataIsLoading: true,
  dataErrorMessage: '',
  applicationsData: null,
  studyOptionsData: null, // also used for new api
  jointApplicationsData: null,
  utbildningData: null,
  studyOptions: null,
  favoriteStudyOptions: getData('favoriteStudyOptions') ? getData('favoriteStudyOptions') : [],
  // for pagination
  currentPage: 1,
  perPage: 15,
  currentItems: [],
  currentItems_edu: [],
  totalItems: 0,
  searchByCategory: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case INIT_FILTERS:
      let hakukaynnissa = { hakukaynnissa: { nimi: { sv: 'Ansökan pågår' } } };
      let koulutusala = payload.data.koulutusala,
        maakunta = payload.data.maakunta,
        yhteishaku = payload.data.yhteishaku,
        koulutustyyppi = [
          ['amk-alempi', { nimi: { sv: 'yrkeshögskola lägre' } }],
          ['amk-ylempi', { nimi: { sv: 'yrkeshögskola högre' } }],
          ['kandi', { nimi: { sv: 'universitet lägre' } }],
          ['kandi-ja-maisteri', { nimi: { sv: 'universitet läre + högre' } }],
          ['maisteri', { nimi: { sv: 'universitet högre' } }],
        ],
        koulutustyyppiObject = {
          'amk-alempi': { nimi: { sv: 'yrkeshögskola lägre' } },
          'amk-ylempi': { nimi: { sv: 'yrkeshögskola högre' } },
          kandi: { nimi: { sv: 'universitet lägre' } },
          'kandi-ja-maisteri': { nimi: { sv: 'universitet läre + högre' } },
          maisteri: { nimi: { sv: 'universitet högre' } },
        },
        opetustapa = payload.data.opetustapa,
        hakutapa = Object.assign(hakukaynnissa, payload.data.hakutapa),
        opetuskieli = payload.data.opetuskieli;

      return {
        ...state,
        filterOptions: payload,
        amnen: Object.keys(koulutusala).map((i) => [i, koulutusala[i]]),
        studieort: Object.keys(maakunta).map((i) => [i, maakunta[i]]),
        ansokningsomgang: Object.keys(yhteishaku)
          .map((i) => [i, yhteishaku[i]])
          .filter((item) => item[1].nimi.sv),
        utbildningstyp: koulutustyyppi,
        undervisningsmetod: Object.keys(opetustapa).map((i) => [i, opetustapa[i]]),
        ansokningssatt: Object.keys(hakutapa).map((i) => [i, hakutapa[i]]),
        undervisningssprak: Object.keys(opetuskieli).map((i) => [i, opetuskieli[i]]),
        //to get state on refresh from URL
        ämnenOption: state.queries.koulutusala
          .filter((ala) => ala.includes('koulutusalataso1')) //removes subcategories
          .map((el) => koulutusala[el.trim()]?.nimi.sv),

        regionOption: state.queries.sijainti.map((el) => maakunta[el.trim()]?.nimi.sv),

        ansökningsomgångOption: state.queries.yhteishaku.map((el) => yhteishaku[el.trim()]?.nimi.sv),

        undervisningsmetodOption: state.queries.opetustapa.map((el) => opetustapa[el.trim()]?.nimi.sv),

        utbildningstypOption: state.queries.koulutustyyppi.map((el) => koulutustyyppiObject[el.trim()]?.nimi.sv),

        ansokningssattOption: state.queries.hakutapa.map((el) => hakutapa[el.trim()]?.nimi.sv),
      };
    case INIT_QUERY_DATA:
      return { ...state, studyOptionsData: payload.data };
    case REFRESH_QUERY_DATA:
      //console.log("REFRESH QUERY payload:", payload)
      return {
        ...state,
        queries: {
          ...state.queries,
          ...payload,
        },
      };
    case SET_QUERY:
      // console.log("SET_QUERY payload: ", { payload });

      // search by Amnen
      // console.log({ payload });
      const amnenOptionsCode = payload?.amnen?.map((term) => {
        let targetItem = state.amnen.filter((option) => option[1].nimi.sv.includes(term));
        let mainCode = targetItem.flat()[0];
        let subCodes = Object.keys(targetItem.flat()[1].alakoodit);
        return [mainCode, subCodes].flat();
      });

      // search by Studieort
      let regionOptionsCode = payload?.studieort?.map((term) => state.studieort.filter((option) => option[1].nimi.sv.includes(term))[0][0]);

      // search by utbildningstyp
      const utbildningstypOptionsCode = payload?.utbildningstyp.map(
        (term) => state.utbildningstyp.filter((option) => option[1].nimi.sv.includes(term))[0][0]
      );

      // search by undervisningsmetod
      const undervisningsmetodCode = payload?.undervisningsmetod.map(
        (term) => state.undervisningsmetod.filter((option) => option[1].nimi.sv.includes(term))[0][0]
      );

      // search by ansokningsomgang
      const ansokningsomgangCode = payload?.ansokningsomgang.map(
        (term) => state.ansokningsomgang.filter((option) => option[1].nimi.sv.includes(term))[0][0]
      );

      // search by ansokningssatt
      const ansokningssattCode = payload?.ansokningssatt.map(
        (term) => state.ansokningssatt.filter((option) => option[1].nimi.sv.includes(term))[0][0]
      );

      // search by Undervisningsspråk
      const undervisningssparkCode = payload?.undervisningssprak.map(
        (term) => state.undervisningssprak.filter((option) => option[1].nimi.sv.includes(term))[0][0]
      );

      // search by HÖGSKOLOR
      // console.log(payload);
      return {
        ...state,
        currentPage: 1,
        queries: {
          ...state.queries,
          page: 1,
          keyword: payload.keyword,
          koulutusala: amnenOptionsCode,
          amnen: payload.amnen,
          studieort: payload.studieort,
          sijainti: regionOptionsCode,
          utbildningstyp: payload.utbildningstyp,
          koulutustyyppi: utbildningstypOptionsCode,
          undervisningsmetod: payload.undervisningsmetod,
          opetustapa: undervisningsmetodCode,
          ansokningsomgang: payload.ansokningsomgang,
          yhteishaku: ansokningsomgangCode,
          ansokningssatt: payload.ansokningssatt,
          hakutapa: ansokningssattCode,
          undervisningssprak: payload.undervisningssprak,
          opetuskieli: undervisningssparkCode,
        },
      };
    case INIT_DATA:
      let forAboutPage = payload.cmsResponse.filter((cms) => cms.sys.contentType.sys.id === 'about');
      let forOriginOptions = payload.cmsResponse.filter((cms) => cms.sys.contentType.sys.id === 'studieort');
      let forAccessibilityPage = payload.cmsResponse.filter((cms) => cms.sys.contentType.sys.id === 'accessibilityStatement');
      let forPrivacyPolicyPage = payload.cmsResponse.filter((cms) => cms.sys.contentType.sys.id === 'privacyPolicy');
      let regionOptions = [];
      forOriginOptions[0].fields.region.forEach((region) => {
        let option = { value: region, label: region };
        regionOptions.push(option);
      });
      return {
        ...state,
        CMSdataForAboutPage: forAboutPage[0].fields,
        CMSdataForAccessibilityPage: forAccessibilityPage[0].fields,
        CMSdataForPrivacyPolicyPage: forPrivacyPolicyPage[0].fields,
        regionOptions: regionOptions,
        dataIsLoading: false,
      };
    case FAIL_DATA:
      return { ...state, dataIsLoading: false, dataErrorMessage: payload.message };
    case GET_OPTION:
      // console.log('GET_OPTION payload:', payload);
      let region = payload.label === 'Studieort' ? payload.option : state.regionOption;
      let utbildningsform = payload.label === 'Undervisingssätt' ? payload.option : state.utbildningsformOption;

      let utbildningstyp = payload.label === 'Utbildningstyp' ? payload.option : state.utbildningstypOption;
      let ansökningsomgång = payload.label === 'Ansokningsomgang' ? payload.option : state.ansökningsomgångOption;
      let ansokningssatt = payload.label === 'Ansökningssätt' ? payload.option : state.ansokningssattOption;
      let ämnen = payload.label === 'Ämnen' ? payload.option : state.ämnenOption;
      let undervisningsmetod = payload.label === 'Undervisningsmetod' ? payload.option : state.undervisningsmetodOption;

      let finska = payload.label === 'Finska' ? payload.option : state.finskaOption;
      let engelska = payload.label === 'Engelska' ? payload.option : state.engelskaOption;
      return {
        ...state,
        ansokningssattOption: ansokningssatt,
        regionOption: region,
        utbildningsformOption: utbildningsform,
        utbildningstypOption: utbildningstyp,
        ansökningsomgångOption: ansökningsomgång,
        ämnenOption: ämnen,
        undervisningsmetodOption: undervisningsmetod,
        engelskaOption: engelska,
        finskaOption: finska,
      };
    case GET_VALUE:
      return {
        ...state,
        searchInputValue: payload.label === 'panel' ? payload.value : state.searchInputValue,
        headSearchInputValue: payload.label === 'head' ? payload.value : state.headSearchInputValue,
      };
    case LOAD_RESULT:
      return { ...state, searchResultIsLoading: true };
    case PAGINATE:
      let { studyOptionsData } = state;
      return {
        ...state,
        queries: {
          ...state.queries,
          page: payload,
        },
        totalItems: studyOptionsData?.length,
        currentPage: payload,
        currentItems_edu: [],
      };
    case STORE_FAVORITE:
      const { favoriteStudyOptions } = state;

      let storedList = [];
      if (favoriteStudyOptions) {
        storedList = [...favoriteStudyOptions, payload];
      }
      storeData('favoriteStudyOptions', storedList);
      return { ...state, favoriteStudyOptions: storedList };
    case REMOVE_FAVORITE:
      let updatedList = state.favoriteStudyOptions?.filter((oid) => oid !== payload);
      storeData('favoriteStudyOptions', updatedList);
      return { ...state, favoriteStudyOptions: updatedList };
    default:
      return state;
  }
};

export default reducer;
