import React, { useState } from 'react';

// Material-UI
import {
  Typography,
  Collapse,
  IconButton,
} from '@material-ui/core';

import { styled } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const CollapsibleHeader = ({ header, content }) => {

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <Typography component='h3' variant='body1' style={{ fontSize: '1rem', fontWeight: '500' }}>{header}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Typography>
      <Collapse orientation="horizontal" in={expanded} >
        <div>
          <Typography
            component='p'
            variant='subtitle1'
            dangerouslySetInnerHTML={{
              __html: content.sv ?? "(text ej tillgänglig på svenska, visar den engelska istället)" + content.en,
            }}
          />
        </div>
      </Collapse>
    </div>
  )
}

export default CollapsibleHeader;

