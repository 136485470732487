import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Card, CardHeader, CardContent, CardActions, Icon, Typography } from '@material-ui/core';

import ShareButton from '../customs/ShareButtton';
import FavoriteButton from '../customs/FavoriteButton';
import { programLogo } from '../../helpers/setLogo';

const setEducationType = (eduType) => {
  if (eduType.includes('yo')) {
    return 'Universitet';
  } else if (eduType.includes('amk')) {
    return 'Yrkeshögskola';
  } else {
    return '';
  }
};

const StudyOptions = ({ data }) => {
  const classes = useStyles();

  return (
    <div className={classes.cardContainer}>
      {data.map((item, index) => (
        <Card className={classes.card} key={index}>
          <CardHeader
            avatar={
              <img
                alt={item.toteutukset[0].toteutusNimi.sv}
                aria-label={item.toteutukset[0].toteutusNimi.sv}
                src={programLogo(item.toteutukset[0].oppilaitosNimi.sv)}
                className={classes.logo}
              />
            }
          />
          <CardHeader
            className={classes.title}
            title={item.toteutukset[0].toteutusNimi.sv}
            titleTypographyProps={{
              component: 'h3',
              variant: 'body2',
              color: 'textPrimary',
              /* className: classes.title, */
            }}
            subheader={setEducationType(item.koulutustyyppi)}
            subheaderTypographyProps={{
              component: 'p',
              variant: 'caption',
              color: 'textPrimary',
              spacing: '3rem',
              className: classes.generalText,
            }}
            style={{ textAlign: 'left' }}
          />
          <CardActions disableSpacing className={classes.cardActions}>
            <FavoriteButton item={item} />
            <ShareButton data={item} oid={item.toteutukset[0].toteutusOid} />
          </CardActions>
          <CardContent className={classes.content}>
            {item.kuvaus ? (
              <Typography
                component='p'
                variant='caption'
                className={classes.description}
                dangerouslySetInnerHTML={{
                  __html: item.kuvaus.sv?.replace(/(<([^>]+)>)/ig, '').replace(/^\s*[\r\n]/gm, ''),
                }}
              />
            ) : (
              <span></span>
            )}
            <Box className={classes.origin}>
              <Typography component='p' variant='subtitle2' color='textPrimary' className={classes.generalText}>
                {item.toteutukset[0].oppilaitosNimi.sv}
              </Typography>
              <Typography component='p' variant='subtitle2' color='textPrimary' className={classes.generalText}>
                {item.toteutukset[0].kunnat.map((kunta) => kunta.nimi.sv).join(', ')}
              </Typography>
            </Box>
          </CardContent>
          <CardActions disableSpacing className={classes.learnMoreBtn}>
            <Typography variant='button' color='primary' className={classes.btn}>
              <a href={`/utbildning/${item.toteutukset[0].toteutusOid}`} style={{ textDecoration: 'none' }}>
                {' '}
                Läs mer{' '}
              </a>
              <Icon className={classes.icon}>double_arrow</Icon>
            </Typography>
          </CardActions>
        </Card>
      ))}
    </div>
  );
};

export default StudyOptions;

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    margin: '1rem auto',
    maxWidth: 1000,
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
    gridTemplateRows: 'none',
    justifyContent: 'center',
    alignContent: 'center',
    [theme.breakpoints.down('md')]: {
      maxWidth: '85%',
      minWidth: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%',
      minWidth: '90%',
    },
  },
  card: {
    position: 'relative',
    margin: 'auto',
    borderRadius: '10px',
    minHeight: 540,
    maxHeight: 540,
    minWidth: 320,
    maxWidth: 320,
  },
  logo: {
    width: theme.spacing(13),
    height: theme.spacing(7),
    objectFit: 'contain',
  },
  description: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 4,
    // wordBreak: 'break-all',
    overflow: 'hidden',
    fontFamily: 'trispace',
    fontSize: '0.9em',
    fontWeight: 'normal',
    textAlign: 'left',
    minHeight: '6em',
  },
  actionArea: {
    '&:hover $focusHighlight': {
      backgroundColor: 'blue',
    },
  },
  icon: {
    margin: '0 auto',
    marginLeft: '5px',
    fontSize: 14,
    lineHeight: '1rem',
  },
  content: {
    padding: theme.spacing(1, 2),
    minHeight: 140,
  },
  btn: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily: 'trispace',
    fontSize: 16,
    position: 'absolute',
    left: 16,
  },
  region: {
    display: 'flex',
    justifyContent: 'space-between',
    /*   alignItems: 'flex-end', */
  },
  status: {
    position: 'absolute',
    zIndex: 2,
    color: '#fefff0',
    textAlign: 'center',
    top: '-0.5rem',
    left: '1rem',
    minWidth: '11rem',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  cardActions: {
    position: 'absolute',
    display: 'block',
    minWidth: '20%',
    top: 10,
    right: 0,
  },
  learnMoreBtn: {
    position: 'absolute',
    minWidth: '100%',
    bottom: 25,
  },
  origin: {
    position: 'absolute',
    bottom: 70,
  },
  title: {
    fontFamily: 'montserrat-extra-bold',
    padding: theme.spacing(2),
    minHeight: '11em',
    maxHeight: '11em',
  },
  generalText: {
    fontFamily: 'trispace',
    fontSize: '0.8em',
  },
}));
