/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

// Material-UI
import { Box, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import mainPhoto from '../../images/Misc/hero-books.webp';

const ComingSoon = () => {
  const classes = useStyles();

  return (
    <Box component='main' className={classes.root}>
      <Container maxWidth='lg' className={classes.container}>
        <div className={classes.headSection}>
          <Typography
            id='main'
            variant='h1'
            style={{
              fontSize: 'calc(2em + 1vw)',
              fontWeight: 'bold',
              fontFamily: 'youngserif',
            }}>
            Kommer
            <br />
            snart!
          </Typography>
          <Typography
            variant='h4'
            style={{
              fontSize: 'calc(1em + 0.1vw)',
              fontFamily: 'trispace',
              padding: '0',
              lineHeight: '2rem',
              maxWidth: '45rem',
            }}>
            <br />
            Snart lanseras den nya tjänsten Studera på svenska i Finland, där du kan hitta spännande
            studiemöjligheter i Svenskfinland!
          </Typography>
        </div>
        <div className={classes.image}>
          <img src={mainPhoto} alt='' className={classes.img} />
        </div>
      </Container>
    </Box>
  );
};
export default ComingSoon;

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    minWidth: '100%',
    maxHeight: '100vh',
    minHeight: '100vh',
    padding: theme.spacing(5, 0),
  },
  container: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  headSection: {
    top: '10rem',
    zIndex: '3',
    marginLeft: '0',
  },
  image: {
    left: '10rem',
    zIndex: '1',
  },
  img: {
    minWidth: '26em',
    maxWidth: '26em',

    [theme.breakpoints.down('xs')]: {
      minWidth: '20em',
      maxWidth: '20em',
    },
  },
}));
