/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';

// Material-UI
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem, InputBase, Checkbox, ListItemText } from '@material-ui/core';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getOption, setQuery } from '../../actions/filterActions';

const SelectField = ({ label, terms, option, refetch }) => {
  const classes = useStyles();
  const [options, setOption] = useState([]);
  const { queries } = useSelector((state) => state.filters);
  const dispatch = useDispatch();

  const selectedOption = (value) => {
    setOption(value);
    dispatch(getOption(label, value));
    if (label.includes('Ämnen')) {
      dispatch(setQuery({ ...queries, amnen: value }));
    }
    if (label.includes('Studieort')) {
      dispatch(setQuery({ ...queries, studieort: value }));
    }
    if (label.includes('Utbildningstyp')) {
      dispatch(setQuery({ ...queries, utbildningstyp: value }));
    }
    if (label.includes('Undervisningsmetod')) {
      dispatch(setQuery({ ...queries, undervisningsmetod: value }));
    }
    if (label.includes('Ansokningsomgang')) {
      dispatch(setQuery({ ...queries, ansokningsomgang: value }));
    }
    if (label.includes('Ansökningssätt')) {
      dispatch(setQuery({ ...queries, ansokningssatt: value }));
    }
  };
  return (
    <FormControl variant='outlined' className={classes.formControl}>
      <InputLabel className={classes.label} id={label} shrink>
        {label}
      </InputLabel>
      <Select
        labelId={label}
        aria-label={label}
        className={classes.select}
        multiple
        value={options}
        onChange={({ target }) => selectedOption(target.value)}
        onClick={refetch}
        onOpen={() => setOption(option)} 
        input={<BootstrapInput />}
        renderValue={(option) => (option && option.length ? option?.join(', ').substr(0, 15) + '...' : '')}
        label={label}>
        {terms.map((t, i) => (
          <MenuItem key={i} value={t[1].nimi.sv}>
            <Checkbox checked={options.indexOf(t[1].nimi.sv) > -1} color='primary' />
            <ListItemText primary={t[1].nimi.sv ? t[1].nimi.sv : t[1].nimi.fi} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default SelectField;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3, 0),
    minWidth: '100%',
  },
  label: {
    color: '#707070',
    fontSize: 18,
    fontFamily: 'trispace',
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(1.5),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    //backgroundColor: fade(theme.palette.common.white, 0.15),
    border: '1px solid #ced4da',
    fontSize: 14,
    paddingLeft: '8px',
    //padding: '10px 20px 10px 12px',

    backgroundColor: '#fafafa',
    //transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font. trispace
    fontFamily: ['trispace'].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);
