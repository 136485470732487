/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

//Components
import ShareButton from '../customs/ShareButtton';
import FavoriteButton from '../customs/FavoriteButton';
import CollapsibleHeader from '../functions/CollapsibleHeader';

// helper
import { programBanner } from '../../helpers/setLogo';

// Material-UI
import { Box, Container, Typography, Button, Link, CircularProgress, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Routing
import { useParams } from 'react-router';

//React Query
import { useQuery } from 'react-query';
import { getSingleProgramme, getSingleEducation } from '../../lib/api';

const dateMoreThanNow = (dateString) => {
  const a = Date.parse(dateString);
  const b = new Date();
  return !!b < a;
};

function dateDiffInDays(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

const Utbildning = () => {
  const classes = useStyles();
  // const [favorite, setFavorite] = useState(false);

  let { program } = useParams();

  // Gets toteutus
  const { isLoading, error, data} = useQuery('single_programme', () => getSingleProgramme(program), { 
    staleTime: 1000 * 60 * 5
  });

  // Gets koulutus
  // Used for "Utbildningens omfattning" and "Examensbenämningar"
  // will not execute untill koulutusOid exist
  const educationQuery = useQuery(['single_education', data?.data?.koulutusOid], () => getSingleEducation(data?.data?.koulutusOid), {
    staleTime: 1000 * 60 * 5,
    enabled: !!data?.data?.koulutusOid,
  });

  const educationType = (school, education) => {
    const bachelor = !!/\(YH\)|(kandidat)/.test(education);
    const master = !!/(magister)|(diplomingenjör)/.test(education);
    const AppliedOrUni = school === 'yo' ? 'Universitet' : 'Yrkeshögskola';
    const level = bachelor && master ? 'lägre och högre' : bachelor ? 'lägre' : 'högre';
    return AppliedOrUni + ' ' + level;
  };

  if (isLoading || error) {
    return (
      <div className={classes.spinner}>
        {isLoading && <CircularProgress />}
        {error && 
        <Typography
         variant='h4'
         style={{ fontSize: '1rem', fontWeight: 'bold', fontFamily: 'trispace', textAlign: 'center'  }}>
         <br />
         Vi hittade inte resursen <br/>
         du sökte efter 
       </Typography>
        }
      </div>
    );
  }
  return (
    <Container component='main' maxWidth='md' className={classes.root}>
      <Box className={classes.headSection}>
        <img src={programBanner(data?.data?.nimi.sv)} alt='' className={classes.demo} />

        <Typography id='main' component='h1' variant='h2' style={{ padding: '1rem 0', fontSize: '2.2rem' }}>
          {data?.data?.nimi.sv}
        </Typography>
      </Box>
      <Box className={classes.subheadSection}>
        <Typography component='h2' variant='subtitle2' className={classes.subheaderTypography}>
          {educationType(data?.data?.koulutustyyppi, data?.data?.nimi.sv)}
        </Typography>
        <Typography component='h2' variant='subtitle2' className={classes.subheaderTypography}>
          {data?.data?.organisaatio?.nimi?.sv}
        </Typography>
        <Typography component='h2' variant='subtitle2' className={classes.subheaderTypography}>
          {data?.data?.organisaatio?.paikkakunta?.nimi?.sv}
        </Typography>
        <div style={{ display: 'flex', marginTop: '15px' }}>
          <FavoriteButton item={data?.data} />
          <ShareButton data={data?.data} oid={data?.data.oid} />
        </div>
      </Box>
      <Box className={classes.mainSection}>
        <div className={classes.desc}>
          {data?.data?.metadata?.kuvaus?.sv && (
            <div>
              <Typography component='h3' variant='body1' className={classes.headers} style={{ marginTop: '0' }}>
                OM UTBILDNINGEN
              </Typography>
              <Typography
                component='p'
                variant='subtitle1'
                dangerouslySetInnerHTML={{
                  __html: data?.data?.metadata?.kuvaus?.sv,
                }}
              />
            </div>
          )}

          {data?.data?.metadata.opetus.opetuskieletKuvaus && (
            <div>
              <Typography component='h3' variant='body1' className={classes.headers}>
                OM UNDERVISNINGSSPRÅKEN
              </Typography>
              <Typography
                component='p'
                variant='subtitle1'
                dangerouslySetInnerHTML={{
                  __html:
                    data?.data?.metadata?.opetus?.opetuskieletKuvaus?.sv ?? //fallback to english if swedish is not available
                    data?.data?.metadata?.opetus?.opetuskieletKuvaus?.en,
                }}
              />
            </div>
          )}

          {/* "lisatiedot" means additional information, and we might as well display it all */}
          <Typography component='h3' variant='body1' className={classes.headers}>
            YTTERLIGARE INFORMATION
          </Typography>
          <br />
          {data?.data?.metadata.opetus.lisatiedot.map((info, i) => (
            <CollapsibleHeader key={i} header={info.otsikko?.nimi?.sv} content={info.teksti} />
          ))}
          <Typography component='h3' variant='body1' className={classes.headers} style={{ marginTop: '4rem' }}>
            SÖK TILL UTBILDNINGEN
          </Typography>
        </div>

        <div className={classes.info}>
          <div className={classes.infoYellow}>
            <div className={classes.infoItem}>
              Utbildningen inleds:{' '}
              <Typography variant='subtitle2' component='p' className={classes.infoTitle}>
                {' '}
                {data?.data?.metadata?.opetus?.koulutuksenAlkamiskausi?.koulutuksenAlkamiskausi?.nimi.sv}{' '}
                {data?.data?.metadata?.opetus?.koulutuksenAlkamiskausi?.koulutuksenAlkamisvuosi}
              </Typography>
            </div>

            <div className={classes.infoItem}>
              <div>Undervisningsspråk: </div>
              <div>
                {data?.data?.metadata.opetus.opetuskieli.map((el, i) => (
                  <Typography key={i} variant='subtitle2' component='p' style={{ textTransform: 'capitalize' }} className={classes.infoTitle}>
                    {el.nimi.sv}
                  </Typography>
                ))}
              </div>
            </div>

            <div className={classes.infoItem}>
              Undervisningssätt:{' '}
              <Typography variant='subtitle2' component='p' className={classes.infoTitle}>
                {data?.data?.metadata.opetus.opetustapa?.map((el, i) => el.nimi.sv)}
              </Typography>
            </div>

            <div className={classes.infoItem}>
              Studieform: {/* handledda studier, flerforms undervising  etc.. */}
              {data?.data?.metadata.opetus.opetusaika.map((el, i) => (
                <Typography key={i} variant='subtitle2' component='p' className={classes.infoTitle}>
                  {el.nimi.sv}
                </Typography>
              ))}
            </div>

            <div className={classes.infoItem}>
              Utbildningens omfattning:{' '}
              <Typography variant='subtitle2' component='p' className={classes.infoTitle}>
                {data?.data?.metadata.opintojenLaajuus?.nimi.sv ?? educationQuery.data?.data?.metadata.opintojenLaajuus?.nimi.sv}{' '}
                {data?.data?.metadata?.opintojenLaajuusyksikko?.nimi.sv ?? educationQuery.data?.data?.metadata?.opintojenLaajuusyksikko?.nimi.sv}
              </Typography>
            </div>

            <div className={classes.infoItem}>
              Planerad längd:{' '}
              <Typography variant='subtitle2' component='p' className={classes.infoTitle}>
                {data?.data?.metadata.opetus.suunniteltuKestoVuodet} år
              </Typography>
            </div>

            <div className={classes.infoItem}>
              Examensbenämningar:{' '}
              <div style={{ paddingBottom: '0.5rem' }}>
                {educationQuery.data?.data?.metadata?.tutkintonimike?.map((el, i, arr) => (
                  <Typography key={i} variant='subtitle2' component='p' className={classes.infoTitle}>
                    {el.nimi.sv}
                    {arr.length - 1 !== i && ','}
                  </Typography>
                ))}
              </div>
              {educationQuery.data?.data?.metadata?.tutkintonimike?.map((el, i, arr) => (
                <Typography key={i} variant='subtitle2' component='p' className={classes.infoTitle}>
                  {el.nimi.en}
                  {arr.length - 1 !== i ? ',' : <br />}
                </Typography>
              ))}
            </div>

            <div className={classes.infoItem}>
              Yrkestitlar:{' '}
              {data?.data?.metadata?.ammattinimikkeet
                ?.filter((item) => item.kieli === 'sv')
                .map((item, i) => (
                  <Typography key={i} variant='subtitle2' component='p' className={classes.infoTitle}>
                    {item?.arvo}
                  </Typography>
                ))}
              {!data?.data?.metadata?.ammattinimikkeet[0] && (
                <Typography variant='subtitle2' component='p' className={classes.infoTitle}>
                  Ej regisrerade av högskolan
                </Typography>
              )}
            </div>

            {data?.data?.metadata.yhteyshenkilot && (
              <div>
                <Typography variant='subtitle2' component='p' style={{ fontSize: '0.7rem', fontFamily: 'trispace', fontWeight: 'bold' }}>
                  Mer info:
                </Typography>
                {!data?.data?.metadata.yhteyshenkilot[0] && (
                  <Typography variant='subtitle2' component='p' className={classes.infoTitle}>
                    Ej regisrerad av högskolan
                  </Typography>
                )}
                <ul className={classes.list}>
                  {data?.data?.metadata.yhteyshenkilot.map((item, i) => (
                    <li key={i}>
                      {item.nimi?.sv}: <br />
                      {item.sahkoposti?.sv}
                      <br />
                      {item.wwwSivu?.sv && (
                        <a href={item.wwwSivu?.sv} rel='noopener noreferrer' target='_blank'>
                          Högskolans webbplats
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div
            className={classes.secondInfo}
            style={{ fontSize: '0.7rem', fontFamily: 'trispace', fontWeight: 'bold', padding: '1rem', marginTop: '2rem' }}>
            Sidan var senat uppdaterad:
            <div style={{ fontWeight: 'normal', paddingTop: '0.5rem' }}>{data?.data?.formatoituModified.sv}</div>
          </div>
        </div>
      </Box>

      <div className={classes.cardContainer}>
        {/* remove empty and duplicate items (especially a problem on testiopintopolku)*/}
        {data?.data?.haut
          ?.filter((item) => item.nimi?.sv)
          .sort()
          .filter((item, pos, ary) => !pos || item !== ary[pos - 1])
          .map((item, i) => (
            <Paper key={i} className={classes.card}>
              <div className={classes.status}>
                {new Date(item.hakuajat[0].alkaa) < new Date() && new Date(item.hakuajat[0].paattyy) > new Date() ? (
                  <div style={{ backgroundColor: '#1b6afe', color: 'white' }}>
                    <div className={classes.statusInner}>Ansökan öppen</div>
                  </div>
                ) : dateMoreThanNow(item.hakuajat[0].paattyy) ? ( // if not passed
                  <div style={{ backgroundColor: '#EAEAEA' }}>
                    <div className={classes.statusInner}>Ansök om {dateDiffInDays(new Date(), new Date(item.hakuajat[0].alkaa))} dagar</div>
                  </div>
                ) : (
                  <div style={{ backgroundColor: '#EAEAEA' }}>
                    <div className={classes.statusInner}>Ansökan stängd</div>
                  </div>
                )}
              </div>
              <Typography
                component='h2'
                variant='subtitle2'
                style={{ marginBottom: '1rem', marginTop: '1rem' }}
                className={classes.ansokanTypography}>
                {item.nimi.sv}:
                {item.hakuajat?.map((time, i) => (
                  <Typography key={i} className={classes.subheaderTypography} style={{ marginTop: '1rem' }}>
                    {time.formatoituAlkaa.sv} - <br /> {time.formatoituPaattyy.sv}
                  </Typography>
                ))}
              </Typography>
            </Paper>
          ))}
      </div>

      <div className={classes.applyBox} style={{ width: '50%' }}>
        <Button className={classes.applyBtn} href={`https://opintopolku.fi/konfo/sv/toteutus/${program}`} component={Link}>
          Gå till utbildningen på studieinfo för att ansöka
        </Button>
      </div>
    </Container>
  );
};
export default Utbildning;

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
    maxWidth: 1800,
    minHeight: '100vh',
    padding: theme.spacing(5, 0),
    marginTop: '3rem',
    backgroundColor: '#FFF',
  },
  headSection: {
    maxWidth: '60%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%',
      flexDirection: 'column-reverse',
    },
  },
  demo: {
    width: '40%',
    height: 'auto',
    paddingBottom: '3rem',
    [theme.breakpoints.down('xs')]: {
      width: '40%',
      padding: '1rem',
    },
  },
  applyDemo: {
    minWidth: '80%',
    border: '1px solid #1B6AFE',
    borderRadius: '10px',
    padding: '1.2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '1px 1px 15px -10px rgb(0 0 0 / 55%)',
    marginBottom: '3rem',
  },
  applyBtn: {
    minWidth: '95%',
    padding: '0.5rem',
    textAlign: 'center',
    color: '#FFFFFF',
    backgroundColor: '#1B6AFE',
    '&:hover': {
      backgroundColor: '#5B96FE',
    },
  },
  subheadSection: {
    maxWidth: '60%',
    margin: '2rem auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  subheaderTypography: {
    fontSize: '1rem',
    fontFamily: 'trispace',
  },
  mainSection: {
    maxWidth: '60%',
    margin: '2rem auto',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      flexDirection: 'column-reverse',
    },
  },
  desc: {
    flexBasis: '60%',
    padding: '1rem 1rem 1rem 0',
    [theme.breakpoints.down('sm')]: {
      padding: '1.2rem',
    },
  },
  info: {
    marginTop: '16px',
    flexBasis: '25%',
    minHeight: '25em',
    height: 'fit-content',
    minWidth: '17em',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: 'auto',
    },
  },
  infoYellow: {
    height: 'fit-content',
    minWidth: '17em',
    backgroundColor: '#FAFFC7',
    borderRadius: '10px',
    boxShadow: '1px 1px 15px -10px rgb(0 0 0 / 55%)',
    padding: '2rem 1rem',
  },
  infoItem: {
    fontSize: '0.7rem',
    marginBottom: '0.5rem',
    fontFamily: 'trispace',
    fontWeight: '600',
  },
  infoTitle: {
    padding: '0 0.9rem',
    paddingTop: '2px',
    fontSize: '0.7rem',
    fontFamily: 'trispace',
    fontWeight: '300',
    lineHeight: '1rem',
  },
  list: {
    padding: 0,
    margin: '0 0 0 20px',
    fontSize: '0.7rem',
    fontFamily: 'trispace',
    fontWeight: '300',
    listStyleType: 'square',
  },
  applySection: {
    maxWidth: '60%',
    margin: 'auto',
    textAlign: 'center',
    backgroundColor: '#EAEAEA',
    borderRadius: '20px',
    boxShadow: '3px 3px 20px -8px rgba(0,0,0,0.75)',
    padding: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%',
    },
  },
  applyBox: {
    margin: 'auto',
    textAlign: 'center',
    color: '#fff',
    padding: '1.2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  applyDate: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  spinner: {
    minWidth: '100%',
    maxWidth: 1800,
    minHeight: '100vh',
    padding: theme.spacing(5, 0),
    margin: '12rem auto',
    textAlign: 'center',
  },
  ansokanContainer: {
    // margin: '0 auto',
    // maxWidth: '60rem',
    maxWidth: '60%',
    margin: '2rem auto',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      flexDirection: 'column-reverse',
    },
  },
  ansokanPaper: {
    padding: '1rem',
    marginBottom: '1rem',
    marginTop: '2rem',
    fontSize: '1rem',
    fontWeight: '500',
    position: 'relative',
  },
  status: {
    position: 'absolute',
    // zIndex: 2,
    // color: '#fefff0',
    textAlign: 'center',
    top: '-1rem',
    // left: '1rem',
    minWidth: '11rem',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '8px',
    paddingBottom: '8px',
    fontFamily: 'trispace',
  },
  statusInner: {
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  programTitle: {
    fontFamily: 'montserrat-extra-bold',
    fontSize: '1rem',
  },
  cardContainer: {
    maxWidth: '60%',
    margin: '2rem auto',
    display: 'flex',
    //justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      flexDirection: 'column-reverse',
    },

    // margin: '1rem auto',
    // maxWidth: 1000,
    // minWidth: 1000,
    // display: 'flex',
    flexBasis: 'row',
    flexWrap: 'wrap',
    // [theme.breakpoints.only('sm')]: {
    //   maxWidth: '85%',
    //   minWidth: '85%',
    //   margin: '1rem auto',
    // },
    // [theme.breakpoints.down('xs')]: {
    //   maxWidth: '85%',
    //   minWidth: '85%',
    //   margin: '1rem auto',
    // },
  },
  card: {
    maxWidth: '16.00rem',
    minWidth: '16.00rem',
    margin: '1rem',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'visible',
    padding: '1rem',

    border: '1px solid #d6d6d6',
    borderRadius: '0',

    [theme.breakpoints.only('sm')]: {
      maxWidth: '85%',
      minWidth: '85%',
      margin: '1rem auto',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '85%',
      minWidth: '85%',
      margin: '1rem auto',
    },
  },
  headers: {
    fontSize: '1.3rem',
    fontWeight: '500',
    fontFamily: 'youngserif',
    color: '#505050',
    marginTop: '2rem',
  },
}));
