import React from 'react';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: '1rem',
    transform: 'translateY(-10rem)',
    left: '50%',
    zIndex: '9999',
  },
  link: {
    width: '10rem',
    left: '-5rem',
    textAlign: 'center',
    position: 'absolute',
    backgroundColor: 'white',
    borderRadius: '3px',
    padding: '0.5rem',
    '&:focus': {
        transform: 'translateY(10rem)',
        transition: '250ms',
     },
  }
}));

// Skip-to-content is a WCAG 2.0 (AA) Accessibility Requirement 
const SkipToContent = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
     <a href={props.href} className={classes.link}>skip to content</a>
    </div>
  );
};
export default SkipToContent;
