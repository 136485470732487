export const INIT_FILTERS = 'INIT_FILTERS';
export const INIT_QUERY_DATA = 'INIT_QUERY_DATA';
export const SET_QUERY = 'SET_QUERY';
export const INIT_DATA = 'INIT_DATA';
export const FAIL_DATA = 'FAIL_DATA';
export const LOAD_RESULT = 'LOAD_RESULT';
export const REMOVE_FAVORITE = 'REMOVE_FAVORITE';
export const STORE_FAVORITE = 'STORE_FAVORITE';
export const GET_OPTION = 'GET_OPTION';
export const GET_VALUE = 'GET_VALUE';
export const PAGINATE = 'PAGINATE';
export const REFRESH_QUERY_DATA = 'REFRESH_QUERY_DATA';

