import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Children
import FavoriteButton from '../customs/FavoriteButton';
import ShareButton from '../customs/ShareButtton';

// Material-UI
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';

import Icon from '@material-ui/core/Icon';

import { programLogo } from '../../helpers/setLogo';

const setEducationType = (eduType) => {
  if (eduType.includes('yo')) {
    return 'Universitet';
  } else if (eduType.includes('amk')) {
    return 'Yrkeshögskola';
  } else {
    return '';
  }
};

const FavoriteItem = ({ item }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: response } = await axios.get(
          `https://testiopintopolku.fi/konfo-backend/external/koulutus/${item}?toteutukset=false&hakukohteet=true&haut=false`
        );
        setData(response);
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    };

    fetchData();
  }, [item]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <img
            alt={data.nimi.sv}
            aria-label={data.nimi.sv}
            src={data.tarjoajat.length === 1 ? programLogo(data.tarjoajat[0].nimi.sv) : programLogo(data.tarjoajat[1].nimi.sv)}
            className={classes.logo}
          />
        }
      />
      <CardHeader
        className={classes.title}
        title={data.nimi.sv}
        titleTypographyProps={{
          component: 'h3',
          variant: 'body2',
          color: 'textPrimary',
          /* className: classes.title, */
        }}
        subheader={setEducationType(data.koulutustyyppi)}
        subheaderTypographyProps={{
          component: 'p',
          variant: 'caption',
          color: 'textPrimary',
          spacing: '3rem',
          className: classes.generalText,
        }}
        style={{ textAlign: 'left' }}
      />
      <CardActions disableSpacing className={classes.cardActions}>
        <FavoriteButton item={data} />

        {data.hakukohteet.length === 0 ? null : <ShareButton data={data} oid={data.hakukohteet.slice(-1)[0].toteutusOid} />}
      </CardActions>
      <CardContent className={classes.content}>
        {data.metadata.kuvaus ? (
          <Typography
            component='p'
            variant='caption'
            className={classes.description}
            dangerouslySetInnerHTML={{
              __html: data.metadata.kuvaus.sv?.replace(/(<([^>]+)>)/gi, '').replace(/^\s*[\r\n]/gm, ''),
            }}
          />
        ) : (
          <span></span>
        )}
        <Box className={classes.origin}>
          <Typography component='p' variant='subtitle2' color='textPrimary' className={classes.generalText}>
            {data.tarjoajat.map((kunta) => kunta.paikkakunta.nimi.sv).join(', ')}
          </Typography>
        </Box>
      </CardContent>
      <CardActions disableSpacing className={classes.learnMoreBtn}>
        <Typography variant='button' color='primary' className={classes.btn}>
          <a href={`/utbildning/${data.hakukohteet.slice(-1)[0].toteutusOid}`} style={{ textDecoration: 'none' }}>
            {' '}
            Läs mer{' '}
          </a>
          <Icon className={classes.icon}>double_arrow</Icon>
        </Typography>
      </CardActions>
    </Card>
  );
};

export default FavoriteItem;

const useStyles = makeStyles((theme) => ({
  loading: {
    position: 'absolute',
    left: '50%',
    top: '50%',
  },
  card: {
    position: 'relative',
    margin: 'auto',
    borderRadius: '10px',
    minHeight: 530,
    maxHeight: 530,
    minWidth: 320,
    maxWidth: 320,
  },
  logo: {
    width: theme.spacing(13),
    height: theme.spacing(7),
    objectFit: 'contain',
  },
  description: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 4,
    wordBreak: 'break-all',
    overflow: 'hidden',
    fontFamily: 'trispace',
    fontSize: '0.9em',
    fontWeight: 'normal',
    textAlign: 'left',
    minHeight: '6em',
  },
  actionArea: {
    '&:hover $focusHighlight': {
      backgroundColor: 'blue',
    },
  },
  icon: {
    margin: '0 auto',
    marginLeft: '5px',
    fontSize: 14,
    lineHeight: '1rem',
  },
  content: {
    padding: theme.spacing(1, 2),
    minHeight: 140,
  },
  btn: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily: 'trispace',
    fontSize: 16,
    position: 'absolute',
    left: 16,
  },
  region: {
    display: 'flex',
    justifyContent: 'space-between',
    /*   alignItems: 'flex-end', */
  },
  status: {
    position: 'absolute',
    zIndex: 2,
    color: '#fefff0',
    textAlign: 'center',
    top: '-0.5rem',
    left: '1rem',
    minWidth: '11rem',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  cardActions: {
    position: 'absolute',
    display: 'block',
    minWidth: '20%',
    top: 10,
    right: 0,
  },
  learnMoreBtn: {
    position: 'absolute',
    minWidth: '100%',
    bottom: 25,
  },
  origin: {
    position: 'absolute',
    bottom: 70,
  },
  title: {
    fontFamily: 'montserrat-extra-bold',
    padding: theme.spacing(2),
    minHeight: '11em',
    maxHeight: '11em',
  },
  generalText: {
    fontFamily: 'trispace',
    fontSize: '0.8em',
  },
}));
