import React from 'react';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { Box, Card, CardHeader, CardContent } from '@material-ui/core';

// REDUX
import { useSelector } from 'react-redux';

const SkeletonCard = () => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <div className={classes.cardTopContainer}>
        <div className={classes.largeAvatar}>
          <Skeleton animation='wave' variant='circle' width={40} height={40} />
        </div>
        <CardHeader
          className={classes.cardHeader}
          title={<Skeleton variant='text' />}
          subheader={<Skeleton variant='text' />}
        />
      </div>

      <CardContent className={classes.content}>
        <Box className={classes.region}>
          <Box>
            <Skeleton animation='wave' height={10} width={160} style={{ marginBottom: 6 }} />
            <Skeleton animation='wave' height={10} width={160} style={{ marginBottom: 6 }} />
            <Skeleton animation='wave' height={10} width={160} style={{ marginBottom: 6 }} />
            <Skeleton animation='wave' variant='text' height={30} width={80} />
          </Box>
          {/* <Box>
            <Skeleton animation='wave' height={10} width={160} />
            <Skeleton animation='wave' variant='text' height={10} width={160} />
            <Skeleton animation='wave' variant='text' height={10} width={160} />
          </Box> */}
        </Box>
      </CardContent>
    </Card>
  );
};
const LoadingSkeleton = () => {
  const { searchResultIsLoading } = useSelector((state) => state.filters);
  const classes = useStyles();
  if (!searchResultIsLoading) return null;
  return (
    <div>
      <Skeleton
        className={classes.center}
        animation='wave'
        variant='text'
        height={32}
        width={120}
      />
      <div className={classes.pagination}>
        <Skeleton animation='wave' variant='circle' width={32} height={32} />
        <Skeleton animation='wave' variant='circle' width={32} height={32} />
        <Skeleton animation='wave' variant='circle' width={32} height={32} />
        <Skeleton animation='wave' variant='circle' width={32} height={32} />
        <Skeleton animation='wave' variant='circle' width={32} height={32} />
        <Skeleton animation='wave' variant='circle' width={32} height={32} />
        <Skeleton animation='wave' variant='circle' width={32} height={32} />
        <Skeleton animation='wave' variant='circle' width={32} height={32} />
        <Skeleton animation='wave' variant='circle' width={32} height={32} />
      </div>

      <div className={classes.cardContainer}>
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
      </div>
    </div>
  );
};

export default LoadingSkeleton;

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    margin: '0 auto',
    marginTop: '0rem',
    maxWidth: 1000,
    minWidth: 1000,
    display: 'flex',
    flexBasis: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.only('sm')]: {
      maxWidth: '85%',
      minWidth: '85%',
      margin: '1rem auto',
      marginTop: '7rem',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '85%',
      minWidth: '85%',
      margin: '1rem auto',
      marginTop: '7rem',
    },
  },
  card: {
    maxWidth: 300,
    minWidth: 300,
    margin: '2rem auto',
    [theme.breakpoints.only('sm')]: {
      maxWidth: '85%',
      minWidth: '85%',
      margin: '1rem auto',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '85%',
      minWidth: '85%',
      margin: '1rem auto',
    },
  },
  cardTopContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  },
  cardHeader: {
    lineHeight: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      flexGrow: '1',
    },
  },
  largeAvatar: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 auto',
    marginTop: '1rem',
    width: theme.spacing(7),
    height: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      flexGrow: '1',
      margin: '1.5rem 4rem 0rem 0rem ',
    },
  },

  media: {
    flexBasis: '49%',
    width: '30%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '9rem',
    },
  },
  icon: {
    marginLeft: '5px',
    fontSize: 14,
  },
  utbildiningform: {
    padding: '5px',
    border: '1px solid black',
    borderRadius: '5px',
    backgroundColor: 'lightblue',
    color: '#fff',
    width: '140px',
    textAlign: 'center',
    fontSize: '1em',
  },
  content: {
    flexBasis: '49%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flext-start',
  },
  btn: {
    maxWidth: '120px',
  },
  region: {
    display: 'flex',
    justifyContent: 'space-between',
    /*   alignItems: 'flex-end', */
  },
  center: {
    margin: '0 auto',
  },
  pagination: {
    width: '20rem',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
}));
