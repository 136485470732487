import React from 'react';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 245,
    minWidth: 245,
    textAlign: 'center',
    [theme.breakpoints.only('sm')]: {
      maxWidth: '85%',
      minWidth: '85%',
      margin: '1rem auto',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '85%',
      minWidth: '85%',
      margin: '1rem auto',
    },
  },

  media: {
    minWidth: '100%',
    height: '10rem',
    [theme.breakpoints.down('sm')]: {
      height: '9rem',
    },
  },
  icon: {
    marginLeft: '5px',
    fontSize: 14,
  },
 header: {
    color: '#505050',
    fontFamily: 'montserrat',
    textTransform: 'uppercase',
    letterSpacing: '0.15rem',
    fontSize: '0.9rem',
    textAlign: 'center',
  }
}));

const Category = ({ subject, onClick }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea
      onClick={onClick}
      className={classes.CardActionArea}
      >
        <CardMedia
          component='img'
          alt=''
          position='center'
          image={subject.image}
          className={classes.media}
        />
        <CardContent>
          <Typography gutterBottom variant='h5' component='h2' className={classes.header}>
            {subject.title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
export default Category;
