// LOGOS
import arcada from '../images/Logos/arcada_logo_black.webp';
import helsinki from '../images/Logos/university-of-helsinki-logo.webp';
import konstuniversitetet from '../images/Logos/konstuniversitetetUniversityofArts.webp';
import aalto from '../images/Logos/aalto.webp';
import abo from '../images/Logos/AboAkademi-logo_black.webp';
import aland from '../images/Logos/Högskolanpååland-alt.webp';
import novia from '../images/Logos/Novia.webp';
import hanken from '../images/Logos/HankenSvenskaHandelshögskolan.webp';
import defaultLogo from '../images/Logos/defaultEDULogo.webp';

// BANNERS
import agriculture from '../images/Categories/agriculture.webp';
import arts from '../images/Categories/art-theatre-music.webp';
import finance from '../images/Categories/economics.webp';
import technology from '../images/Categories/engineering.webp';
import general from '../images/Categories/general.webp';
import medicine from '../images/Categories/healthcare.webp';
import history from '../images/Categories/history.webp';
import language from '../images/Categories/language.webp';
import science from '../images/Categories/nautral-sciences.webp';
import education from '../images/Categories/psychology.webp';
import law from '../images/Categories/social-sciences-law.webp';
import tourism from '../images/Categories/tourism.webp';
import transport from '../images/Categories/transport.webp';

export const programLogo = (key) => {
  if (key?.toLowerCase().includes('arcada')) {
    return arcada;
  } else if (key?.toLowerCase().includes('hanken')) {
    return hanken;
  } else if (key?.toLowerCase().includes('handelshögskolan')) {
    return hanken;
  } else if (key?.toLowerCase().includes('åbo akademi')) {
    return abo;
  } else if (key?.toLowerCase().includes('helsingfors')) {
    return helsinki;
  } else if (key?.toLowerCase().includes('konstuniversitetet')) {
    return konstuniversitetet;
  } else if (key?.toLowerCase().includes('aalto')) {
    return aalto;
  } else if (key?.toLowerCase().includes('åland')) {
    return aland;
  } else if (key?.toLowerCase().includes('novia')) {
    return novia;
  } else {
    return defaultLogo;
  }
};

export const programBanner = (title) => {
  if (title?.toLowerCase().includes('arts')) {
    return arts;
  } else if (title?.toLowerCase().includes('education')) {
    return education;
  } else if (title?.toLowerCase().includes('finance')) {
    return finance;
  } else if (title?.toLowerCase().includes('history')) {
    return history;
  } else if (title?.toLowerCase().includes('lang')) {
    return language;
  } else if (title?.toLowerCase().includes('law')) {
    return law;
  } else if (title?.toLowerCase().includes('medicine')) {
    return medicine;
  } else if (title?.toLowerCase().includes('science')) {
    return science;
  } else if (title?.toLowerCase().includes('turism')) {
    return tourism;
  } else if (title?.toLowerCase().includes('tech')) {
    return technology;
  } else if (title?.toLowerCase().includes('handel')) {
    return finance;
  } else if (title?.toLowerCase().includes('finans')) {
    return finance;
  } else if (title?.toLowerCase().includes('ekonom')) {
    return finance;
  } else if (title?.toLowerCase().includes('histori')) {
    return history;
  } else if (title?.toLowerCase().includes('kultur')) {
    return history;
  } else if (title?.toLowerCase().includes('tradition')) {
    return history;
  } else if (title?.toLowerCase().includes('formgiv')) {
    return arts;
  } else if (title?.toLowerCase().includes('hantverk')) {
    return arts;
  } else if (title?.toLowerCase().includes('konst')) {
    return arts;
  } else if (title?.toLowerCase().includes('musik')) {
    return arts;
  } else if (title?.toLowerCase().includes('lant')) {
    return agriculture;
  } else if (title?.toLowerCase().includes('miljö')) {
    return agriculture;
  } else if (title?.toLowerCase().includes('bruk')) {
    return agriculture;
  } else if (title?.toLowerCase().includes('vetenskaper')) {
    return finance;
  } else if (title?.toLowerCase().includes('språk')) {
    return language;
  } else if (title?.toLowerCase().includes('kommunik')) {
    return language;
  } else if (title?.toLowerCase().includes('inform')) {
    return technology;
  } else if (title?.toLowerCase().includes('teknik')) {
    return technology;
  } else if (title?.toLowerCase().includes('vård')) {
    return medicine;
  } else if (title?.toLowerCase().includes('välmå')) {
    return medicine;
  } else if (title?.toLowerCase().includes('tjänster')) {
    return medicine;
  } else if (title?.toLowerCase().includes('undervis')) {
    return science;
  } else if (title?.toLowerCase().includes('fostra')) {
    return science;
  } else if (title?.toLowerCase().includes('psykologi')) {
    return science;
  } else if (title?.toLowerCase().includes('trafik')) {
    return transport;
  } else if (title?.toLowerCase().includes('transport')) {
    return transport;
  } else {
    return general;
  }
};
