import {
  INIT_FILTERS,
  INIT_QUERY_DATA,
  SET_QUERY,
  INIT_DATA,
  FAIL_DATA,
  STORE_FAVORITE,
  REMOVE_FAVORITE,
  GET_OPTION,
  GET_VALUE,
  PAGINATE,
  LOAD_RESULT,
  REFRESH_QUERY_DATA,
} from './types';

// Contentful CMS
import { client } from '../contentful';

export const getFilterOptions = (data) => (dispatch) => {
  dispatch({ type: INIT_FILTERS, payload: data });
};

export const getData = (data) => (dispatch) => {
  dispatch({ type: INIT_QUERY_DATA, payload: data });
};

export const setQuery =
  ({ ...args }) =>
  (dispatch) => {
    dispatch({ type: SET_QUERY, payload: args });
  };

export const setRefreshQuery =
  ({ ...args }) =>
  (dispatch) => {
    dispatch({ type: REFRESH_QUERY_DATA, payload: args });
  };

export const paginate = (page_number) => (dispatch) => {
  dispatch({ type: PAGINATE, payload: page_number });
};

// OLD
export const loadData = () => async (dispatch) => {
  try {
    const cmsResponse = await client.getEntries();
    dispatch({
      type: INIT_DATA,
      payload: {
        cmsResponse: cmsResponse.items,
      },
    });
  } catch (error) {
    dispatch({
      type: FAIL_DATA,
      payload: { message: error.message },
    });
  }
};

export const getOption = (label, option) => (dispatch) => {
  dispatch({
    type: GET_OPTION,
    payload: { label, option },
  });
};

export const getValue = (value, label) => (dispatch) => {
  dispatch({
    type: GET_VALUE,
    payload: { value, label },
  });
};

export const loadResult = () => (dispatch) => {
  dispatch({
    type: LOAD_RESULT,
  });
};

export const removeFavorite = (oid) => (dispatch) => {
  dispatch({
    type: REMOVE_FAVORITE,
    payload: oid,
  });
};

export const storeFavorite = (oid) => (dispatch) => {
  dispatch({
    type: STORE_FAVORITE,
    payload: oid,
  });
};
