import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getOption, getValue, loadResult, paginate, setQuery } from '../../actions/filterActions';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActionArea, CardContent, CardMedia, Typography, Icon, Box } from '@material-ui/core';

const CategoryCard = ({ subjects, images, category, refetch }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  /* console.log({ category: category }); */
  const {
    queries,
    regionOptions,
    ÄmnenOptions,
    searchInputValue,
    finskaOption,
    engelskaOption,
    regionOption,
    ämnenOption,
    ansökningsomgångOption,
    utbildningstypOption,
    utbildningsformOption,
    searchByCategory,
  } = useSelector((state) => state.filters);

  useEffect(() => {
    if (searchByCategory) {
      handleSearch();
    }
  }, []);

  const handleSearch = () => {
    // SEARCH
    setQueryToUrl();
    dispatch(loadResult());
    dispatch(paginate(1));
  };
  const setQueryToUrl = () => {
    const queryHash =
      '#' +
      `${searchInputValue ? 'frisökning=' + searchInputValue + '&' : ''}` +
      `${regionOption.join() ? 'studieort=' + regionOption.join() + '&' : ''}` +
      `${utbildningstypOption.join() ? 'utbildningstyp=' + utbildningstypOption.join() + '&' : ''}` +
      `${ämnenOption.join() ? 'ämnen=' + ämnenOption.join() + '&' : ''}` +
      `${ansökningsomgångOption.join() ? 'ansökningsomgång=' + ansökningsomgångOption.join() + '&' : ''}` +
      `${utbildningsformOption.join() ? 'utbildningsform=' + utbildningsformOption.join() + '&' : ''}` +
      `${engelskaOption ? 'engelska=' + engelskaOption + '&' : ''}` +
      `${finskaOption ? 'finska=' + finskaOption + '&' : ''}`;

    window.history.replaceState(null, null, queryHash.toLowerCase().slice(0, -1));
  };

  const handleClick = (sub) => {
    if (window.innerWidth < 600) {
      window.scrollTo({
        top: 580,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: 350,
        behavior: 'smooth',
      });
    }
    if (location.pathname !== '/search') {
      history.push('/search');
    }
    /*     dispatc()); */
    const selected = [];

    // Studieorter
    if (regionOptions.find((option) => option.value === sub.title)) {
      selected.push(sub.title);
      dispatch(getOption('Studieort', selected));
    }

    // Kategorier
    if (ÄmnenOptions.find((option) => option.value === sub.title)) {
      selected.push(sub.title);
      dispatch(getOption('Ämnen', selected));
    }

    //Högskolor
    if (category === 'HÖGSKOLOR') {
      const target = sub.title; //.split(' ')[0];
      dispatch(getValue(target.toLowerCase(), 'panel'));
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    // NEW codes
    if (category === 'KATEGORIER') {
      dispatch(setQuery({ ...queries, amnen: [sub[1].nimi.sv], keyword: '', studieort: [] }));
    }
    if (category === 'STUDIEORTER') {
      dispatch(setQuery({ ...queries, studieort: [sub[1].nimi.sv], keyword: '', amnen: [] }));
    }
    if (category === 'HÖGSKOLOR') {
      // console.log({ sub });
      dispatch(setQuery({ ...queries, keyword: sub.title, studieort: [], amnen: [] }));
    }

    setTimeout(refetch, 1000);
  };

  return (
    <div className={classes.root} id='category-items'>
      {subjects.map((sub, i) => (
        <div key={i}>
          <Card className={classes.card} onClick={() => handleClick(sub)}>
            <CardActionArea className={classes.cardActionArea}>
              {category === 'HÖGSKOLOR' ? (
                <div>
                  <div className={classes.mediaContainer}>
                    <CardMedia component='img' alt='' position='center' image={sub.image} className={classes.schoolLogo} />
                  </div>
                  <CardContent className={classes.content}>
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box>
                        <Typography variant='subtitle2' style={{ fontFamily: 'montserrat-bold', fontSize: '1.3rem', lineHeight: '1.4rem' }}>
                          {sub.title}
                        </Typography>
                        <Typography variant='button' style={{ fontFamily: 'trispace', fontSize: '1rem', lineHeight: '1.4rem' }}>
                          {sub.location}
                        </Typography>
                      </Box>
                      <Box style={{ textAlign: 'right' }}></Box>
                    </Box>
                    <br />
                    <div className={classes.btnContainer}>
                      <Typography className={classes.btn} color='primary'>
                        Se alla program
                        <Icon className={classes.icon}>double_arrow</Icon>
                      </Typography>
                    </div>
                  </CardContent>
                </div>
              ) : (
                <div>
                  <CardMedia component='img' alt='' position='center' image={sub.image} className={classes.media} />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant='h5' component='h2' className={classes.header}>
                      {sub[1].nimi.sv}
                    </Typography>
                  </CardContent>
                </div>
              )}
            </CardActionArea>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default CategoryCard;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1rem auto',
    maxWidth: 1000,
    minWidth: 1000,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexBasis: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.only('sm')]: {
      maxWidth: '85%',
      minWidth: '85%',
      margin: '1rem auto',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '85%',
      minWidth: '85%',
      margin: '1rem auto',
    },
  },
  card: {
    maxWidth: 300,
    minWidth: 300,
    margin: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    paddingTop: '30px',
    paddingBottom: '10px',
    paddingRight: '16px',
    paddingLeft: '16px',
    '&:last-child': {
      paddingBottom: '10px',
    },
  },
  mediaContainer: {
    height: '10rem',
    padding: '1rem',
  },
  schoolLogo: {
    maxHeight: '8rem',
    maxWidth: '100%',
    objectFit: 'contain',
    paddingTop: '1rem',
    paddingBottom: '1rem',

    [theme.breakpoints.down('sm')]: {
      height: '9rem',
    },
  },
  media: {
    maxHeight: '8rem',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '9rem',
    },
  },
  icon: {
    marginLeft: '5px',
    fontSize: 14,
  },
  header: {
    height: '4rem',
    fontFamily: 'montserrat',
    textTransform: 'uppercase',
    letterSpacing: '0.15rem',
    fontSize: '0.9rem',
    textAlign: 'center',
    color: '#505050',

    // paddingTop: '30px',
    // paddingRight: '16px',
    // paddingBottom: '10px',
    // paddingLeft: '16px',
  },
  content: {
    height: 200,
    padding: '2rem',
    paddingTop: '1rem',
  },
  btnContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    padding: '1.5rem',
  },
  btn: {
    fontFamily: 'trispace',
    fontSize: '1rem',
    textTransform: 'none',
  },
}));
