/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

// Material-UI
import { Box, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const NoMatch = () => {
  const classes = useStyles();

  return (
    <Box component='main' className={classes.root}>
          <Container maxWidth='lg' className={classes.container}>
        <div className={classes.headSection}>
          <Typography
            variant='h1'
            style={{ fontSize: '3.5rem', fontWeight: 'bold', fontFamily: 'youngserif', textAlign: 'center' }}>
            404 

          </Typography>
          <Typography
            variant='h4'
            style={{ fontSize: '1rem', fontWeight: 'bold', fontFamily: 'trispace', textAlign: 'center'  }}>
            <br />
            Vi hittade inte resursen <br/>
            du sökte efter<br/>  
            <br />
            <br />
          </Typography>
        </div>
      </Container>
    </Box>
  );
};
export default NoMatch;

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
    maxWidth: 1800,
    minHeight: '100vh',
    padding: theme.spacing(5, 0),
    marginTop: '3rem',
  },
  container: {
    maxWidth: '90%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'flex-end',
    '@media (max-width:450px)': {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  headSection: {
   marginTop: '5rem',
  },
}));
