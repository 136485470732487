import React, { useState, useEffect } from 'react';


const NotLoaded = ({errorMsg}) => {
  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    if (seconds === 0) {
      window.location.reload(false);
    }
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  if (errorMsg) {
    return (
      <div style={{ margin: 'auto', textAlign: 'center' }}>
        <h3>OOPS!</h3>
        <h4>{errorMsg}</h4>
        <p>Reload automatically after {seconds} seconds ...</p>
      </div>
    );
  }
};

export default NotLoaded;
