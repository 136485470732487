import React from 'react';

// Material-UI
import { InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//import SearchIcon from '@material-ui/icons/Search';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { setQuery } from '../../actions/filterActions';

const SearchField = ({ placeholder }) => {
  const classes = useStyles();

  const { queries } = useSelector((state) => state.filters);
  const dispatch = useDispatch();

  return (
    <div className={classes.search}>
      {/* <div className={classes.searchIcon}>
        <SearchIcon />
      </div> */}
      <InputBase
        placeholder={placeholder}
        value={queries.keyword}
        onChange={(e) => dispatch(setQuery({ ...queries, keyword: e.target.value }))}
        onFocus={(e) => e.target.select()}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
      />
    </div>
  );
};

export default SearchField;

const useStyles = makeStyles((theme) => ({
  search: {
    height: '2.5rem',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #d4d4d4',
    /* backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    }, */
    marginLeft: 0,
    width: '100%',
    tabIndex: 0,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    minWidth: '100%',
    backgroundColor: 'inherit',
  },
  inputInput: {
    backgroundColor: '#fafafa',
    borderRadius: '0.4rem',
    //color: '#000',
    color: '#707070',
    fontSize: 16,
    fontFamily: 'trispace',
    padding: theme.spacing(1, 1, 1, 1),
    // vertical padding + font size from searchIcon
    //paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: '12ch',
      '&:focus': {
        minWidth: '20ch',
      },
    },
  },
}));
