import React from 'react';

// Material-UI
import { makeStyles } from '@material-ui/styles';
import { Box, Typography, Paper } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';


import {
    Link
  } from 'react-router-dom';

const About = () => {
  const classes = useStyles();
 
  return (
    <Box component='main' style={{ minHeight: '84.9vh' }}>
      <div className={classes.title}>
        <Typography
          id='main'
          variant='h1'
          component='h1'
          style={{ fontSize: 'calc(2em + 1vw)', fontWeight: 'bold', fontFamily: 'youngserif' }}>
          Sidkarta
        </Typography>
      </div>
      <div className={classes.text}>
        {/* <Typography  
            component='p' 
            variant='subtitle1'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna 
        </Typography> */}


        <Timeline>
            <Paper elevation={3} className={classes.paper}  style={{ margin: '2rem auto', marginBottom: '2rem'}}>
                <Link to="/" className={classes.link}>Hem</Link>
            </Paper>
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>  
                    <Paper elevation={3} className={classes.paper}>
                        <Link to="/favorites" className={classes.link}>Sparade Utbildninagar</Link>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>
                        <Link to="/about" className={classes.link}>Om projektet</Link>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>
                        <Link to="/sidkarta" className={classes.link}>Sidkarta</Link>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>
                        <Link to="/sekretesspolicy" className={classes.link}>Sekretess policy</Link>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot />
                    </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>
                        <Link to="/tillganglighet" className={classes.link}>Tillgänglighets utlåtande</Link>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
        </Timeline>
      </div>
    </Box>
  );
};

export default About;

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    marginTop: '5rem',
    padding: '20px 60px',
    [theme.breakpoints.down('sm')]: {
      flex: '1',
      padding: '0',
    },
  },
  text: {
    maxWidth: '30rem',
    padding: '1rem',
    margin: '1rem auto',
  },
  paper: {
    position: 'relative',
    top: '-0.5rem',

    width: '10rem',
    padding: '0.5rem',
    fontFamily: 'trispace',
  },
  link: {
    color: '#636363',
    fontFamily: 'trispace',
    textDecoration: 'none',
  }
}));
