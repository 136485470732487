import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: ['montserrat'],
  },
  palette: {
    primary: {
      //main: '#5b96ff', // old primary
      main: '#1b6afe', // accessible primary
      contrastText: '#fff',
    },
    secondary: {
      main: '#FAFFC7',
    },
    default: {
      main: '#5b96ff',
    },
  },
  overrides: {
    MuiAvatar: {
      img: {
        objectFit: 'cover',
      },
    },
  },
});
theme.typography.h2 = {
  fontFamily: 'youngserif',
  padding: '1rem',
  fontSize: '1.8rem',
  '@media (min-width:600px)': {
    fontSize: '2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '3rem',
  },
};
theme.typography.h4 = {
  fontFamily: 'youngserif',
  padding: '1rem',
  fontSize: '1rem',
  '@media (min-width:600px)': {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
};
theme.typography.body1 = {
  color: '#000',
  fontFamily: 'montserrat-medium',
};
theme.typography.body2 = {
  fontFamily: 'youngserif',
  color: '#505050',
};
theme.typography.button = {
  fontFamily: 'trispace',
  color: '#888',
};
theme.typography.subtitle1 = {
  fontFamily: 'montserrat',
  fontSize: '0.9rem',
  fontWeight: '400',
  lineHeight: '1.75',
};

export default theme;
