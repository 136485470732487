import React from 'react';
import FavoriteItem from './FavoriteItem';

// REDUX
import { useSelector } from 'react-redux';

// Material-UI
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

const Favorites = () => {
  const classes = useStyles();
  const { favoriteStudyOptions } = useSelector((state) => state.filters);

  return (
    <Box component='main' style={{ minHeight: '84.9vh' }}>
      <div className={classes.title}>
        <Typography id='main' variant='h1' style={{ fontSize: 'calc(2em + 1vw)', fontWeight: 'bold', fontFamily: 'youngserif' }}>
          Mina utbildningar
        </Typography>
        <Typography variant='overline' color='initial' className={classes.result}>
          <Icon style={{ paddingRight: 5 }}>school</Icon>&nbsp;&nbsp;
          <span>
            sökresultat&nbsp;(&nbsp;<b>{favoriteStudyOptions?.length}</b>&nbsp;)
          </span>
        </Typography>
      </div>
      <div className={classes.cardContainer}>
        {favoriteStudyOptions.map((item, index) => (
          <div key={index}>
            <FavoriteItem item={item} />
          </div>
        ))}
      </div>
    </Box>
  );
};

export default Favorites;

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    marginTop: '5rem',
    padding: '20px 60px',
    [theme.breakpoints.down('sm')]: {
      flex: '1',
      padding: '0',
    },
  },
  result: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '11em',
    margin: 'auto',
  },
  cardContainer: {
    margin: '1rem auto',
    maxWidth: 1000,
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
    gridTemplateRows: 'none',
    justifyContent: 'center',
    alignContent: 'center',
    [theme.breakpoints.down('md')]: {
      maxWidth: '85%',
      minWidth: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%',
      minWidth: '90%',
    },
  },
  card: {
    maxWidth: 300,
    minWidth: 300,
    margin: '1rem',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'visible',

    border: '1px solid #d6d6d6',
    borderRadius: '0',

    [theme.breakpoints.only('sm')]: {
      maxWidth: '85%',
      minWidth: '85%',
      margin: '1rem auto',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '85%',
      minWidth: '85%',
      margin: '1rem auto',
    },
  },
  cardHeader: {
    lineHeight: '1.5rem',
    padding: '2rem',
    minHeight: '8rem',
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionArea: {
    '&:hover $focusHighlight': {
      backgroundColor: 'blue',
    },
  },
  focusHighlight: {},
  icon: {
    margin: '0 auto',
    marginLeft: '5px',
    fontSize: 14,
    lineHeight: '1rem',
  },
  logoContainer: {
    marginTop: '1rem',
    marginLeft: '1rem',
    paddingTop: '1rem',
    padding: '1rem',
    paddingBottom: '0',
    height: '6rem',
    width: '14.5rem',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'contain',

    [theme.breakpoints.down('sm')]: {
      margin: '1rem 4rem',
    },
  },
  utbildiningform: {
    border: '1px solid #eee',
    backgroundColor: '#ddd',
    width: 'auto',
    textAlign: 'center',
    fontSize: '1em',
    padding: '5px',
    margin: '1em auto',
  },
  content: {
    flexBasis: '49%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flext-start',
    lineHeight: '1.5rem',

    padding: '2rem',
    paddingBottom: '6rem',
    paddingTop: '0',
  },
  btn: {
    fontFamily: 'trispace',
    textTransform: 'none',
    fontSize: 14,
    lineHeight: '1rem',

    position: 'absolute',
    bottom: '2rem',
    left: '2rem',
  },
  btnContainer: {
    position: 'absolute',
    bottom: 0,
    padding: '1rem',
  },
  favShareBtns: {
    position: 'absolute',
    right: '0',
    top: '2rem',
  },
  region: {
    display: 'flex',
    justifyContent: 'space-between',
    /*   alignItems: 'flex-end', */
  },
  status: {
    position: 'absolute',
    zIndex: 2,
    color: '#fefff0',
    textAlign: 'center',
    top: '-0.5rem',
    left: '1rem',
    minWidth: '11rem',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  programTitle: {
    fontFamily: 'montserrat-extra-bold',
  },
  generalText: {
    fontFamily: 'trispace',
    fontSize: '0.8em',
  },
}));
