/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

// Components
import Home from './components/layouts/Home';
import NavBar from './components/layouts/NavBar';
import Footer from './components/layouts/Footer';
import Utbildning from './components/layouts/Utbildning';
import NoMatch from './components/layouts/NoMatch';
import ComingSoon from './components/layouts/ComingSoon';
import Favorites from './components/layouts/Favorites';
import About from './components/layouts/About';
import SiteMap from './components/layouts/SiteMap';
import Accessibility from './components/layouts/Accessibility';

// Material-UI
import { CssBaseline } from '@material-ui/core';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { loadData } from './actions/filterActions';
import { setRefreshQuery } from './actions/filterActions';

// Routing
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import SkipToContent from './components/functions/SkipToContent';
import PrivacyPolicy from './components/layouts/PrivacyPolicy';

const App = () => {
  const { queries } = useSelector((state) => state.filters);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 10,
      behavior: 'smooth',
    });
    query();
    dispatch(loadData());
  }, [dispatch]);

  const query = () => {
    const params = new URLSearchParams(window.location.search);
    const queryString = window.location.search.split('#')[0].substring(1);
    const arr = queryString.split('&');
    let obj = {};

    //turn keys value pairs into object
    arr.forEach((el, i) => {
      let queryKey = el.split('=')[0];

      // if key is valid query key
      if (queryKey in queries) {
        //if key exist in query
        if (params.get(queryKey)) {
          try {
            let val = params.getAll(queryKey)[0];

            //many values
            if (val.includes(',')) {
              obj[queryKey] = val.split(',');

              //numbers
            } else if (/^\d+$/.test(val)) {
              obj[queryKey] = parseInt(val);

              //not a number
            } else {
              obj[queryKey] = params.getAll(queryKey);
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
    });

    dispatch(
      setRefreshQuery({
        ...obj,
      })
    );
  };

  // const CookieCheck = () => {
  //   if (
  //     document.cookie
  //       .split(';')
  //       .some((item) => item.includes('sps-token=pD7VskfmDYsMyBgHVunVC5peHaKywpgR'))
  //   ) {
  //     /* console.log('Authetication token valid'); */
  //     return null;
  //   } else {
  //     return <Redirect to='/kommer-snart' />;
  //   }
  // };

  return (
    <Router>
      <CssBaseline />
      {/* <CookieCheck /> */}

      <SkipToContent href='#main' />

      <Switch>
        <Route exact path='/'>
          <NavBar />
          <Home />
          <Footer />
        </Route>
        <Route exact path='/search'>
          <NavBar />
          <Home />
          <Footer />
        </Route>
        <Route exact path='/utbildning/:program'>
          <NavBar />
          <Utbildning />
          <Footer />
        </Route>

        <Route exact path='/kommer-snart'>
          <ComingSoon />
        </Route>

        <Route exact path='/favorites'>
          <NavBar />
          <Favorites />
          <Footer />
        </Route>

        <Route exact path='/about'>
          <NavBar />
          <About />
          <Footer />
        </Route>

        <Route exact path='/sidkarta'>
          <NavBar />
          <SiteMap />
          <Footer />
        </Route>

        <Route exact path='/sekretesspolicy'>
          <NavBar />
          <PrivacyPolicy />
          <Footer />
        </Route>

        <Route exact path={['/tillganglighet', '/tillgänglighet']}>
          <NavBar />
          <Accessibility />
          <Footer />
        </Route>

        <Route>
          <NavBar />
          <NoMatch />
          <Footer />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
