import React from 'react';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import { Pagination, PaginationItem } from '@material-ui/lab';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { paginate } from '../../actions/filterActions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
  button: {
    fontFamily: 'trispace' ,
    borderRadius: '16px',
    fontWeight: '900',
    fontSize: '0.7rem',
    color: '#505050',
    backgroundColor: '#ffffff',
  },
  selectedItem: {
    backgroundColor: '#1b6afe !important' ,
    color: '#ffffff !important',
  },
  focusedItem: {
    backgroundColor: '#8bb3ff !important'
  }     
}));

const PaginationOutlined = ({total}) => {
  const classes = useStyles();

  const {perPage, currentPage } = useSelector((state) => state.filters);
  const dispatch = useDispatch();

  const handleChange = (event, value) => {
    if (window.innerWidth < 600) {
      window.scrollTo({
        top: 580,
        behavior: 'smooth'
      });
    } else {
      window.scrollTo({
        top: 350,
        behavior: 'smooth'
      });
    }
    dispatch(paginate(value))
  }

  return (
    <div className={classes.root}>
      <Pagination
        page={currentPage ? currentPage : 1}
        count={Math.ceil(total / (perPage ? perPage : 15) )}
        onChange={(event, value) => handleChange(event, value)}
        variant='outlined'
        color='primary'
        renderItem={(item)=> <PaginationItem {...item} 
          classes={{root:classes.button, selected:classes.selectedItem, focusVisible:classes.focusedItem}} />
        }
      />
    </div>
  );
};
export default PaginationOutlined;
