import React from 'react';

// Material-UI
import { Button, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getOption, setQuery } from '../../actions/filterActions';


const FilterChips = () => {
  const classes = useStyles();

  const {
    regionOption,
    ansökningsomgångOption,
    ämnenOption,
    utbildningstypOption,
    ansokningssattOption,
    undervisningsmetodOption,
    queries
  } = useSelector((state) => state.filters);

  const dispatch = useDispatch();

  const handleClick = (filter, optionLabel, option) => {
    dispatch(
      getOption(
        optionLabel,
        option.filter(
          value => value !== filter
        )
      )
    );

    // Handle deletion of chips 
    let value = option.filter(
      value => value !== filter
    )
    if (optionLabel.includes('Ämnen')) {
      dispatch(setQuery({ ...queries, amnen: value }));
    }
    if (optionLabel.includes('Studieort')) {
      dispatch(setQuery({ ...queries, studieort: value }));
    }
    if (optionLabel.includes('Utbildningstyp')) {
      dispatch(setQuery({ ...queries, utbildningstyp: value }));
    }
    if (optionLabel.includes('Undervisningsmetod')) {
      dispatch(setQuery({ ...queries, undervisningsmetod: value }));
    }
    if (optionLabel.includes('Ansokningsomgang')) {
      dispatch(setQuery({ ...queries, ansokningsomgang: value }));
    }
    if (optionLabel.includes('Ansökningssätt')) {
      dispatch(setQuery({ ...queries, ansokningssatt: value }));
    }
  }

  const Chip = ({ filter, optionLabel, option }) => {
    return (
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        endIcon={<Icon>close</Icon>}
        onClick={() => handleClick(filter, optionLabel, option)}
      >
        {filter}
      </Button>
    )
  }
  const renderRegionChips = regionOption?.map((value, index) =>
    <Chip key={index} filter={value} optionLabel='Studieort' option={regionOption} />)

  const renderAnsökningsomgångChips = ansökningsomgångOption?.map((value, index) =>
    <Chip key={index} filter={value} optionLabel='Ansokningsomgang' option={ansökningsomgångOption} />)

  const renderÄmnenOptionChips = ämnenOption?.map((value, index) =>
    <Chip key={index} filter={value} optionLabel='Ämnen' option={ämnenOption} />)

  const renderUtbildningstypChips = utbildningstypOption?.map((value, index) =>
    <Chip key={index} filter={value} optionLabel='Utbildningstyp' option={utbildningstypOption} />)

  const renderAnsokningssattChips = ansokningssattOption?.map((value, index) =>
    <Chip key={index} filter={value} optionLabel='Ansökningssätt' option={ansokningssattOption} />)

  const renderUndervisningsmetodChips = undervisningsmetodOption?.map((value, index) =>
    <Chip key={index} filter={value} optionLabel='Undervisningsmetod' option={undervisningsmetodOption} />)

  return (
    <div className={classes.root} spacing={3}>
      {renderRegionChips &&
        <>
          {renderRegionChips}
          {renderAnsökningsomgångChips}
          {renderÄmnenOptionChips}
          {renderUtbildningstypChips}
          {renderAnsokningssattChips}
          {renderUndervisningsmetodChips}
        </>
      }
    </div>
  );
};

export default FilterChips;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 1000,
    backgroundColor: 'transparent',
    borderRadius: '10px',
    //boxShadow: '3px 3px 20px -8px rgba(0,0,0,0.75)',
    margin: '2rem auto',
  },
  button: {
    borderRadius: '5px',
    margin: '5px',
    padding: '0px 8px',

    // accessibility color corrections  
    borderColor: '#2564db',
    color: '#2564db'
  },
}));
