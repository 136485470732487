import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import LoadingSkeleton from '../customs/LoadingSkeleton';
import StudyOptions from '../layouts/StudyOptions';
import PaginationOutlined from './Pagination';
import noResultImage from '../../images/Misc/no-result.webp';

// REDUX
import { useDispatch } from 'react-redux';
import { getData } from '../../actions/filterActions';

// Material-UI
import { Typography, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const SearchResult = ({ isLoading, isError, error, data }) => {
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      history.push({ pathname: '/search', search: data.config.url.split('?').pop() });
      dispatch(getData(data));
    }
  }, [data, dispatch, history]);


  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (isError) {
    return <div>{error.message}</div>;
  }
  return (
    <div>
      {data?.data.total >= 1 ? (
        <div>
          <div className={classes.root}>
            <Icon>school</Icon>
            <Typography variant='overline' color='initial' style={{ paddingLeft: '5px' }}>
              sökresultat (<strong> {data?.data.total} </strong>)
            </Typography>
          </div>
          {data?.data.total > 15 && <PaginationOutlined total={data.data.total} />}
          <StudyOptions data={data?.data.hits} />
          {data?.data.total > 15 && <PaginationOutlined total={data.data.total} />}
        </div>
      ) : (
        data?.data.total === 0 && (
          <div>
            <div className={classes.root}>
              <Icon>school</Icon>
              <Typography variant='overline' color='initial' style={{ paddingLeft: '5px' }}>
                sökresultat (<strong> {data?.data.total} </strong>)
              </Typography>
            </div>
            <div className={classes.root}>
              <img src={noResultImage} alt='home-page' className={classes.img} />
            </div>

            <Typography
              className={classes.root}
              variant='body1'
              color='textSecondary'
              style={{ paddingLeft: '5px', fontFamily: 'trispace', textAlign: 'center' }}>
              Inga resultat! <br />
              Försök med något annat.
            </Typography>
          </div>
        )
      )}
    </div>
  );
};

export default SearchResult;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    display: 'block',
    minWidth: '26em',
    maxWidth: '26em',

    [theme.breakpoints.down('xs')]: {
      minWidth: '20em',
      maxWidth: '20em',
    },
  },
}));
