import React, { useState } from 'react';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import { Popper, Fade, IconButton, Icon } from '@material-ui/core';

// react-share
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { FacebookIcon, EmailIcon, LinkedinIcon, TwitterIcon, WhatsappIcon } from 'react-share';

const ShareButton = ({ data, oid }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;
  const URL = `https://studerapasvenska.fi/utbildning/${oid}`;

  return (
    <div>
      <IconButton size='small' aria-label='share' aria-describedby={id} onClick={handleClick}>
        <Icon color='primary'>share</Icon>
      </IconButton>
      <Popper id={id} open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div className={classes.paper}>
              <FacebookShareButton url={URL}>
                <FacebookIcon size={28} round={true} quote={data.nimi.sv} hashtg='#Studera pa svenska i Finland' />
              </FacebookShareButton>
              <LinkedinShareButton url={URL} title={data.nimi.sv} summary='Studera pa svenska i Finland' source='https://studerapasvenska.fi'>
                <LinkedinIcon size={28} round={true} />
              </LinkedinShareButton>
              <TwitterShareButton url={URL} title={data.nimi.sv} via='Studera pa svenska i Finland'>
                <TwitterIcon size={28} round={true} />
              </TwitterShareButton>
              <WhatsappShareButton url={URL} title='studera pa svenska i finland'>
                <WhatsappIcon size={28} round={true} />
              </WhatsappShareButton>
              <EmailShareButton url={URL} subject='Studera pa svenska i Finland' body={data.nimi.sv}>
                <EmailIcon size={28} round={true} />
              </EmailShareButton>
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  );
};
export default ShareButton;

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    borderRadius: '20px',
    border: '1px solid #eee',
  },
}));
